import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 0px;
  z-index: 17;

  #stars {
    position: absolute;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 117px 1613px #ffffff, 1488px 635px #ffffff, 944px 914px #ffffff,
      647px 277px #ffffff, 1792px 1205px #ffffff, 656px 1517px #ffffff,
      820px 1839px #ffffff, 1153px 1400px #ffffff, 870px 13px #ffffff,
      550px 702px #ffffff, 1155px 1056px #ffffff, 88px 1709px #ffffff,
      1450px 1090px #ffffff, 1929px 457px #ffffff, 1390px 905px #ffffff,
      1771px 269px #ffffff, 1741px 669px #ffffff, 432px 64px #ffffff,
      563px 996px #ffffff, 1918px 1873px #ffffff, 1845px 1211px #ffffff,
      231px 1503px #ffffff, 37px 220px #ffffff, 1970px 495px #ffffff,
      1812px 925px #ffffff, 67px 1398px #ffffff, 535px 279px #ffffff,
      1837px 829px #ffffff, 1945px 685px #ffffff, 1677px 1817px #ffffff,
      1317px 1415px #ffffff, 1785px 905px #ffffff, 1787px 1554px #ffffff,
      802px 1296px #ffffff, 512px 1101px #ffffff, 583px 1364px #ffffff,
      336px 558px #ffffff, 979px 334px #ffffff, 106px 792px #ffffff,
      204px 34px #ffffff, 1845px 1763px #ffffff, 445px 1599px #ffffff,
      386px 453px #ffffff, 471px 952px #ffffff, 1466px 1676px #ffffff,
      1885px 303px #ffffff, 51px 1717px #ffffff, 1211px 299px #ffffff,
      1546px 1887px #ffffff, 1067px 33px #ffffff, 1088px 1326px #ffffff,
      1938px 760px #ffffff, 470px 648px #ffffff, 1213px 269px #ffffff,
      1767px 78px #ffffff, 977px 976px #ffffff, 1926px 175px #ffffff,
      722px 1512px #ffffff, 945px 227px #ffffff, 1811px 99px #ffffff,
      1912px 1406px #ffffff, 1602px 1243px #ffffff, 610px 449px #ffffff,
      654px 1393px #ffffff, 1930px 1193px #ffffff, 258px 1184px #ffffff,
      89px 265px #ffffff, 824px 1494px #ffffff, 1506px 1435px #ffffff,
      1027px 753px #ffffff, 1px 1197px #ffffff, 530px 1161px #ffffff,
      864px 1555px #ffffff, 1610px 1604px #ffffff, 1035px 1114px #ffffff,
      1456px 133px #ffffff, 1196px 1253px #ffffff, 361px 1037px #ffffff,
      834px 351px #ffffff, 436px 1676px #ffffff, 1194px 1007px #ffffff,
      1141px 647px #ffffff, 319px 454px #ffffff, 937px 1769px #ffffff,
      1872px 1013px #ffffff, 733px 643px #ffffff, 1250px 511px #ffffff,
      189px 296px #ffffff, 1639px 163px #ffffff, 1584px 336px #ffffff,
      1912px 1343px #ffffff, 1298px 1307px #ffffff, 1750px 902px #ffffff,
      1129px 845px #ffffff, 1899px 1470px #ffffff, 1427px 232px #ffffff,
      1391px 838px #ffffff, 1225px 1819px #ffffff, 190px 1366px #ffffff,
      1865px 518px #ffffff, 203px 1383px #ffffff, 1455px 614px #ffffff,
      423px 354px #ffffff, 1678px 1790px #ffffff, 241px 608px #ffffff,
      1089px 730px #ffffff, 1342px 38px #ffffff, 1848px 249px #ffffff,
      1874px 1785px #ffffff, 1040px 1837px #ffffff, 751px 261px #ffffff,
      510px 1975px #ffffff, 52px 795px #ffffff, 1786px 1310px #ffffff,
      498px 712px #ffffff, 190px 375px #ffffff, 1341px 722px #ffffff,
      43px 1394px #ffffff, 1821px 1687px #ffffff, 106px 130px #ffffff,
      1717px 1978px #ffffff, 168px 151px #ffffff, 183px 740px #ffffff,
      945px 1381px #ffffff, 669px 1170px #ffffff, 1285px 1816px #ffffff,
      110px 1217px #ffffff, 1623px 813px #ffffff, 869px 647px #ffffff,
      867px 582px #ffffff, 735px 1240px #ffffff, 519px 1896px #ffffff,
      132px 156px #ffffff, 1649px 193px #ffffff, 241px 1109px #ffffff,
      643px 484px #ffffff, 574px 1282px #ffffff, 1952px 564px #ffffff,
      1978px 145px #ffffff, 329px 903px #ffffff, 1674px 617px #ffffff,
      1978px 558px #ffffff, 1808px 1715px #ffffff, 1526px 1238px #ffffff,
      475px 1330px #ffffff, 810px 425px #ffffff, 1709px 634px #ffffff,
      1658px 336px #ffffff, 425px 194px #ffffff, 352px 96px #ffffff,
      148px 180px #ffffff, 1139px 1046px #ffffff, 1809px 1233px #ffffff,
      1669px 171px #ffffff, 263px 1394px #ffffff, 534px 715px #ffffff,
      396px 1008px #ffffff, 589px 1445px #ffffff, 1190px 381px #ffffff,
      1709px 279px #ffffff, 520px 891px #ffffff, 1136px 1867px #ffffff,
      1280px 1233px #ffffff, 836px 296px #ffffff, 1348px 646px #ffffff,
      1539px 913px #ffffff, 423px 781px #ffffff, 1271px 1805px #ffffff,
      696px 564px #ffffff, 1549px 804px #ffffff, 303px 1555px #ffffff,
      1449px 1903px #ffffff, 66px 687px #ffffff, 1164px 856px #ffffff,
      1958px 1326px #ffffff, 125px 157px #ffffff, 508px 1669px #ffffff,
      465px 725px #ffffff, 1925px 1440px #ffffff, 405px 793px #ffffff,
      278px 110px #ffffff, 1084px 1065px #ffffff, 1077px 705px #ffffff,
      663px 1844px #ffffff, 734px 263px #ffffff, 870px 1761px #ffffff,
      103px 1169px #ffffff, 1506px 1295px #ffffff, 1883px 926px #ffffff,
      335px 1361px #ffffff, 1126px 1284px #ffffff, 257px 1165px #ffffff,
      837px 580px #ffffff, 1211px 1362px #ffffff, 1137px 1380px #ffffff,
      135px 632px #ffffff, 1491px 1965px #ffffff, 1098px 195px #ffffff,
      506px 417px #ffffff, 693px 1243px #ffffff, 622px 1862px #ffffff,
      1412px 1343px #ffffff, 948px 1894px #ffffff, 1315px 1363px #ffffff,
      754px 1098px #ffffff, 1931px 930px #ffffff, 1831px 342px #ffffff,
      1751px 1839px #ffffff, 84px 775px #ffffff, 1662px 1488px #ffffff,
      617px 1769px #ffffff, 1869px 1292px #ffffff, 963px 432px #ffffff,
      371px 1114px #ffffff, 37px 642px #ffffff, 21px 1184px #ffffff,
      602px 366px #ffffff, 414px 524px #ffffff, 282px 244px #ffffff,
      1689px 868px #ffffff, 943px 681px #ffffff, 898px 679px #ffffff,
      449px 1774px #ffffff, 1678px 1313px #ffffff, 475px 1811px #ffffff,
      1146px 1509px #ffffff, 1151px 1863px #ffffff, 1617px 846px #ffffff,
      82px 1077px #ffffff, 324px 1317px #ffffff, 1516px 885px #ffffff,
      1706px 1526px #ffffff, 1925px 1180px #ffffff, 553px 967px #ffffff,
      1072px 536px #ffffff, 1715px 1816px #ffffff, 185px 286px #ffffff,
      1362px 1600px #ffffff, 628px 1938px #ffffff, 1187px 412px #ffffff,
      569px 211px #ffffff, 1959px 1356px #ffffff, 1571px 105px #ffffff,
      319px 1111px #ffffff, 36px 1364px #ffffff, 502px 1788px #ffffff,
      1051px 1993px #ffffff, 1617px 773px #ffffff, 424px 1507px #ffffff,
      1623px 1955px #ffffff, 307px 662px #ffffff, 183px 1048px #ffffff,
      1919px 1453px #ffffff, 1006px 1817px #ffffff, 468px 673px #ffffff,
      1142px 1375px #ffffff, 1228px 443px #ffffff, 1734px 552px #ffffff,
      20px 1041px #ffffff, 1783px 334px #ffffff, 98px 1237px #ffffff,
      1356px 1940px #ffffff, 853px 1779px #ffffff, 1910px 560px #ffffff,
      1174px 1656px #ffffff, 110px 1724px #ffffff, 542px 1771px #ffffff,
      1758px 1931px #ffffff, 1463px 1401px #ffffff, 1155px 84px #ffffff,
      1504px 835px #ffffff, 750px 322px #ffffff, 407px 1900px #ffffff,
      1600px 1141px #ffffff, 657px 886px #ffffff, 526px 714px #ffffff,
      18px 836px #ffffff, 1546px 1548px #ffffff, 22px 469px #ffffff,
      594px 1466px #ffffff, 1160px 1078px #ffffff, 627px 1055px #ffffff,
      195px 699px #ffffff, 1099px 684px #ffffff, 530px 551px #ffffff,
      1160px 1325px #ffffff, 894px 727px #ffffff, 1157px 98px #ffffff,
      136px 1483px #ffffff, 1875px 1975px #ffffff, 1803px 566px #ffffff,
      318px 1073px #ffffff, 1866px 1656px #ffffff, 543px 414px #ffffff,
      719px 474px #ffffff, 1115px 738px #ffffff, 353px 875px #ffffff,
      184px 1938px #ffffff, 1854px 1534px #ffffff, 420px 1698px #ffffff,
      1480px 1550px #ffffff, 522px 203px #ffffff, 1897px 1904px #ffffff,
      975px 1708px #ffffff, 1774px 602px #ffffff, 1908px 274px #ffffff,
      61px 715px #ffffff, 983px 1156px #ffffff, 326px 1013px #ffffff,
      641px 290px #ffffff, 1522px 120px #ffffff, 405px 1637px #ffffff,
      1021px 1099px #ffffff, 631px 1145px #ffffff, 982px 1967px #ffffff,
      200px 651px #ffffff, 795px 351px #ffffff, 790px 1082px #ffffff,
      144px 1572px #ffffff, 1542px 901px #ffffff, 158px 1524px #ffffff,
      849px 1843px #ffffff, 1807px 203px #ffffff, 1747px 45px #ffffff,
      1603px 1738px #ffffff, 617px 1966px #ffffff, 342px 748px #ffffff,
      1779px 1173px #ffffff, 1428px 152px #ffffff, 589px 1998px #ffffff,
      1940px 1838px #ffffff, 115px 272px #ffffff, 1217px 1395px #ffffff,
      1402px 1491px #ffffff, 1833px 1814px #ffffff, 243px 966px #ffffff,
      319px 578px #ffffff, 813px 364px #ffffff, 669px 882px #ffffff,
      551px 134px #ffffff, 1819px 920px #ffffff, 740px 1826px #ffffff,
      1021px 952px #ffffff, 1575px 453px #ffffff, 324px 419px #ffffff,
      929px 417px #ffffff, 885px 1112px #ffffff, 503px 187px #ffffff,
      1908px 362px #ffffff, 1063px 1601px #ffffff, 169px 1792px #ffffff,
      789px 963px #ffffff, 1697px 948px #ffffff, 1761px 1810px #ffffff,
      1844px 1591px #ffffff, 1709px 949px #ffffff, 1402px 1396px #ffffff,
      1037px 225px #ffffff, 1832px 518px #ffffff, 1728px 1782px #ffffff,
      194px 1421px #ffffff, 1395px 742px #ffffff, 1478px 1325px #ffffff,
      40px 593px #ffffff, 1732px 117px #ffffff, 51px 158px #ffffff,
      1598px 1672px #ffffff, 701px 849px #ffffff, 1403px 1979px #ffffff,
      145px 1414px #ffffff, 550px 906px #ffffff, 1366px 460px #ffffff,
      142px 1379px #ffffff, 34px 1864px #ffffff, 1346px 308px #ffffff,
      293px 998px #ffffff, 21px 1868px #ffffff, 540px 1033px #ffffff,
      60px 746px #ffffff, 1602px 1476px #ffffff, 180px 804px #ffffff,
      345px 1982px #ffffff, 1439px 640px #ffffff, 939px 1834px #ffffff,
      20px 432px #ffffff, 492px 1549px #ffffff, 109px 1579px #ffffff,
      1796px 1403px #ffffff, 1079px 519px #ffffff, 1664px 389px #ffffff,
      1627px 1061px #ffffff, 823px 419px #ffffff, 1399px 1882px #ffffff,
      1906px 344px #ffffff, 1189px 848px #ffffff, 117px 882px #ffffff,
      1262px 33px #ffffff, 1048px 434px #ffffff, 1208px 1309px #ffffff,
      1616px 408px #ffffff, 1833px 853px #ffffff, 1433px 1656px #ffffff,
      811px 1861px #ffffff, 439px 1672px #ffffff, 1105px 248px #ffffff,
      328px 1652px #ffffff, 13px 1658px #ffffff, 685px 987px #ffffff,
      985px 403px #ffffff, 1664px 1206px #ffffff, 1993px 1925px #ffffff,
      440px 917px #ffffff, 1835px 319px #ffffff, 1404px 1907px #ffffff,
      624px 1443px #ffffff, 843px 954px #ffffff, 478px 1567px #ffffff,
      895px 1602px #ffffff, 1231px 871px #ffffff, 1267px 1646px #ffffff,
      475px 334px #ffffff, 784px 796px #ffffff, 1294px 199px #ffffff,
      109px 702px #ffffff, 1978px 362px #ffffff, 291px 940px #ffffff,
      971px 1343px #ffffff, 74px 719px #ffffff, 36px 715px #ffffff,
      1007px 1423px #ffffff, 860px 314px #ffffff, 631px 177px #ffffff,
      1900px 1590px #ffffff, 1239px 1348px #ffffff, 1346px 1270px #ffffff,
      1934px 1475px #ffffff, 1553px 559px #ffffff, 588px 1969px #ffffff,
      670px 1269px #ffffff, 1484px 376px #ffffff, 20px 1424px #ffffff,
      1396px 8px #ffffff, 969px 244px #ffffff, 1807px 538px #ffffff,
      1873px 891px #ffffff, 636px 1142px #ffffff, 1474px 1562px #ffffff,
      763px 350px #ffffff, 663px 700px #ffffff, 500px 1469px #ffffff,
      1302px 722px #ffffff, 181px 291px #ffffff, 266px 893px #ffffff,
      1403px 654px #ffffff, 492px 460px #ffffff, 1503px 1369px #ffffff,
      23px 1662px #ffffff, 349px 333px #ffffff, 1435px 1017px #ffffff,
      1441px 705px #ffffff, 1708px 1446px #ffffff, 1041px 911px #ffffff,
      1063px 780px #ffffff, 1158px 1356px #ffffff, 767px 1454px #ffffff,
      1912px 797px #ffffff, 1731px 1759px #ffffff, 1378px 1390px #ffffff,
      1815px 1364px #ffffff, 960px 270px #ffffff, 1343px 427px #ffffff,
      275px 203px #ffffff, 1319px 1092px #ffffff, 1455px 770px #ffffff,
      283px 1503px #ffffff, 1505px 901px #ffffff, 1738px 1561px #ffffff,
      1526px 1935px #ffffff, 1757px 669px #ffffff, 1640px 620px #ffffff,
      1750px 722px #ffffff, 748px 66px #ffffff, 1149px 540px #ffffff,
      159px 953px #ffffff, 200px 1426px #ffffff, 515px 1110px #ffffff,
      1552px 737px #ffffff, 1094px 1459px #ffffff, 778px 799px #ffffff,
      1031px 523px #ffffff, 743px 1825px #ffffff, 1100px 882px #ffffff,
      1088px 1836px #ffffff, 255px 599px #ffffff, 67px 1361px #ffffff,
      247px 1721px #ffffff, 1722px 346px #ffffff, 1822px 155px #ffffff,
      452px 1973px #ffffff, 415px 1960px #ffffff, 1109px 57px #ffffff,
      273px 1392px #ffffff, 404px 1071px #ffffff, 1212px 353px #ffffff,
      370px 460px #ffffff, 795px 1523px #ffffff, 1932px 340px #ffffff,
      51px 1473px #ffffff, 1268px 364px #ffffff, 1512px 1862px #ffffff,
      1678px 1801px #ffffff, 1796px 579px #ffffff, 254px 251px #ffffff,
      1466px 1717px #ffffff, 893px 379px #ffffff, 1153px 923px #ffffff,
      913px 1808px #ffffff, 791px 789px #ffffff, 417px 1924px #ffffff,
      1336px 1599px #ffffff, 1695px 908px #ffffff, 1120px 114px #ffffff,
      493px 1949px #ffffff, 68px 1905px #ffffff, 969px 481px #ffffff,
      1420px 1095px #ffffff, 800px 1117px #ffffff, 390px 234px #ffffff,
      356px 1644px #ffffff, 1098px 1486px #ffffff, 1360px 521px #ffffff,
      149px 1198px #ffffff, 354px 747px #ffffff, 1749px 487px #ffffff,
      470px 76px #ffffff, 1672px 289px #ffffff, 1731px 545px #ffffff,
      1547px 1590px #ffffff, 498px 692px #ffffff, 398px 1592px #ffffff,
      1846px 1237px #ffffff, 1537px 1474px #ffffff, 1726px 1374px #ffffff,
      1922px 858px #ffffff, 376px 321px #ffffff, 985px 227px #ffffff,
      234px 1421px #ffffff, 760px 745px #ffffff, 1990px 1132px #ffffff,
      1560px 1597px #ffffff, 338px 1310px #ffffff, 1924px 1664px #ffffff,
      547px 1747px #ffffff, 1639px 1282px #ffffff, 1202px 337px #ffffff,
      1985px 779px #ffffff, 737px 456px #ffffff, 89px 501px #ffffff,
      963px 792px #ffffff, 655px 1447px #ffffff, 1492px 1994px #ffffff,
      1171px 254px #ffffff, 892px 827px #ffffff, 1735px 442px #ffffff,
      1474px 1187px #ffffff, 846px 1518px #ffffff, 557px 1805px #ffffff,
      738px 945px #ffffff, 795px 68px #ffffff, 663px 1956px #ffffff,
      1607px 290px #ffffff, 1524px 15px #ffffff, 1097px 1911px #ffffff,
      157px 1939px #ffffff, 935px 1065px #ffffff, 1809px 1708px #ffffff,
      164px 1157px #ffffff, 83px 855px #ffffff, 625px 501px #ffffff,
      814px 398px #ffffff, 552px 695px #ffffff, 597px 1546px #ffffff,
      1237px 1417px #ffffff, 628px 284px #ffffff, 866px 767px #ffffff,
      1403px 1394px #ffffff, 765px 1563px #ffffff, 1648px 109px #ffffff,
      1205px 1659px #ffffff, 921px 1313px #ffffff, 1319px 243px #ffffff,
      18px 125px #ffffff, 7px 777px #ffffff, 181px 418px #ffffff,
      1062px 1892px #ffffff, 382px 106px #ffffff, 994px 751px #ffffff,
      964px 234px #ffffff, 40px 118px #ffffff, 278px 706px #ffffff,
      1540px 1978px #ffffff, 425px 1661px #ffffff, 1050px 321px #ffffff,
      735px 1729px #ffffff, 1438px 260px #ffffff, 1229px 1109px #ffffff,
      186px 1041px #ffffff, 244px 1184px #ffffff, 392px 1472px #ffffff,
      670px 1249px #ffffff, 1260px 1443px #ffffff, 1977px 1511px #ffffff,
      1240px 773px #ffffff, 303px 513px #ffffff, 63px 1530px #ffffff,
      610px 792px #ffffff, 1987px 1647px #ffffff, 676px 1597px #ffffff,
      1740px 1244px #ffffff, 816px 1661px #ffffff, 351px 802px #ffffff,
      252px 1082px #ffffff, 31px 365px #ffffff, 1453px 984px #ffffff,
      667px 1233px #ffffff, 1247px 1800px #ffffff, 839px 270px #ffffff,
      775px 913px #ffffff, 1966px 1398px #ffffff, 499px 813px #ffffff,
      922px 1982px #ffffff, 1409px 1902px #ffffff, 1499px 1766px #ffffff,
      721px 899px #ffffff, 788px 807px #ffffff, 989px 1355px #ffffff,
      1248px 1274px #ffffff, 849px 1091px #ffffff, 1799px 1036px #ffffff,
      1486px 700px #ffffff, 170px 1989px #ffffff, 1275px 799px #ffffff,
      772px 2000px #ffffff, 1642px 362px #ffffff, 216px 940px #ffffff,
      1893px 281px #ffffff, 1944px 1298px #ffffff, 1294px 400px #ffffff,
      1523px 441px #ffffff, 1829px 340px #ffffff, 468px 170px #ffffff,
      1099px 967px #ffffff, 1331px 665px #ffffff, 1174px 1553px #ffffff,
      1567px 325px #ffffff, 1028px 1399px #ffffff, 781px 1451px #ffffff,
      1912px 1954px #ffffff, 874px 873px #ffffff, 1298px 1722px #ffffff,
      1879px 706px #ffffff, 57px 1221px #ffffff, 1116px 1432px #ffffff,
      48px 811px #ffffff, 101px 916px #ffffff, 677px 304px #ffffff,
      1203px 639px #ffffff, 1391px 199px #ffffff, 1895px 1988px #ffffff,
      1462px 1023px #ffffff, 1216px 1751px #ffffff, 1261px 663px #ffffff,
      1290px 1119px #ffffff, 137px 1793px #ffffff, 1052px 1470px #ffffff,
      1561px 226px #ffffff, 1156px 402px #ffffff, 709px 693px #ffffff,
      1040px 1911px #ffffff, 1624px 1115px #ffffff, 551px 475px #ffffff,
      416px 1090px #ffffff, 1183px 451px #ffffff, 58px 765px #ffffff,
      743px 1016px #ffffff, 198px 369px #ffffff, 1645px 1503px #ffffff,
      997px 22px #ffffff, 1447px 1323px #ffffff, 379px 883px #ffffff,
      1171px 1195px #ffffff, 919px 133px #ffffff, 1400px 517px #ffffff,
      725px 804px #ffffff, 1600px 699px #ffffff, 357px 581px #ffffff,
      266px 1713px #ffffff, 848px 1749px #ffffff, 1963px 1045px #ffffff,
      119px 1136px #ffffff;

    animation: animStar 50s linear infinite;

    &::after {
      content: " ";
      position: absolute;
      top: 0px;
      width: 1px;
      height: 1px;
      background: transparent;
      box-shadow: 117px 1613px #ffffff, 1488px 635px #ffffff,
        944px 914px #ffffff, 647px 277px #ffffff, 1792px 1205px #ffffff,
        656px 1517px #ffffff, 820px 1839px #ffffff, 1153px 1400px #ffffff,
        870px 13px #ffffff, 550px 702px #ffffff, 1155px 1056px #ffffff,
        88px 1709px #ffffff, 1450px 1090px #ffffff, 1929px 457px #ffffff,
        1390px 905px #ffffff, 1771px 269px #ffffff, 1741px 669px #ffffff,
        432px 64px #ffffff, 563px 996px #ffffff, 1918px 1873px #ffffff,
        1845px 1211px #ffffff, 231px 1503px #ffffff, 37px 220px #ffffff,
        1970px 495px #ffffff, 1812px 925px #ffffff, 67px 1398px #ffffff,
        535px 279px #ffffff, 1837px 829px #ffffff, 1945px 685px #ffffff,
        1677px 1817px #ffffff, 1317px 1415px #ffffff, 1785px 905px #ffffff,
        1787px 1554px #ffffff, 802px 1296px #ffffff, 512px 1101px #ffffff,
        583px 1364px #ffffff, 336px 558px #ffffff, 979px 334px #ffffff,
        106px 792px #ffffff, 204px 34px #ffffff, 1845px 1763px #ffffff,
        445px 1599px #ffffff, 386px 453px #ffffff, 471px 952px #ffffff,
        1466px 1676px #ffffff, 1885px 303px #ffffff, 51px 1717px #ffffff,
        1211px 299px #ffffff, 1546px 1887px #ffffff, 1067px 33px #ffffff,
        1088px 1326px #ffffff, 1938px 760px #ffffff, 470px 648px #ffffff,
        1213px 269px #ffffff, 1767px 78px #ffffff, 977px 976px #ffffff,
        1926px 175px #ffffff, 722px 1512px #ffffff, 945px 227px #ffffff,
        1811px 99px #ffffff, 1912px 1406px #ffffff, 1602px 1243px #ffffff,
        610px 449px #ffffff, 654px 1393px #ffffff, 1930px 1193px #ffffff,
        258px 1184px #ffffff, 89px 265px #ffffff, 824px 1494px #ffffff,
        1506px 1435px #ffffff, 1027px 753px #ffffff, 1px 1197px #ffffff,
        530px 1161px #ffffff, 864px 1555px #ffffff, 1610px 1604px #ffffff,
        1035px 1114px #ffffff, 1456px 133px #ffffff, 1196px 1253px #ffffff,
        361px 1037px #ffffff, 834px 351px #ffffff, 436px 1676px #ffffff,
        1194px 1007px #ffffff, 1141px 647px #ffffff, 319px 454px #ffffff,
        937px 1769px #ffffff, 1872px 1013px #ffffff, 733px 643px #ffffff,
        1250px 511px #ffffff, 189px 296px #ffffff, 1639px 163px #ffffff,
        1584px 336px #ffffff, 1912px 1343px #ffffff, 1298px 1307px #ffffff,
        1750px 902px #ffffff, 1129px 845px #ffffff, 1899px 1470px #ffffff,
        1427px 232px #ffffff, 1391px 838px #ffffff, 1225px 1819px #ffffff,
        190px 1366px #ffffff, 1865px 518px #ffffff, 203px 1383px #ffffff,
        1455px 614px #ffffff, 423px 354px #ffffff, 1678px 1790px #ffffff,
        241px 608px #ffffff, 1089px 730px #ffffff, 1342px 38px #ffffff,
        1848px 249px #ffffff, 1874px 1785px #ffffff, 1040px 1837px #ffffff,
        751px 261px #ffffff, 510px 1975px #ffffff, 52px 795px #ffffff,
        1786px 1310px #ffffff, 498px 712px #ffffff, 190px 375px #ffffff,
        1341px 722px #ffffff, 43px 1394px #ffffff, 1821px 1687px #ffffff,
        106px 130px #ffffff, 1717px 1978px #ffffff, 168px 151px #ffffff,
        183px 740px #ffffff, 945px 1381px #ffffff, 669px 1170px #ffffff,
        1285px 1816px #ffffff, 110px 1217px #ffffff, 1623px 813px #ffffff,
        869px 647px #ffffff, 867px 582px #ffffff, 735px 1240px #ffffff,
        519px 1896px #ffffff, 132px 156px #ffffff, 1649px 193px #ffffff,
        241px 1109px #ffffff, 643px 484px #ffffff, 574px 1282px #ffffff,
        1952px 564px #ffffff, 1978px 145px #ffffff, 329px 903px #ffffff,
        1674px 617px #ffffff, 1978px 558px #ffffff, 1808px 1715px #ffffff,
        1526px 1238px #ffffff, 475px 1330px #ffffff, 810px 425px #ffffff,
        1709px 634px #ffffff, 1658px 336px #ffffff, 425px 194px #ffffff,
        352px 96px #ffffff, 148px 180px #ffffff, 1139px 1046px #ffffff,
        1809px 1233px #ffffff, 1669px 171px #ffffff, 263px 1394px #ffffff,
        534px 715px #ffffff, 396px 1008px #ffffff, 589px 1445px #ffffff,
        1190px 381px #ffffff, 1709px 279px #ffffff, 520px 891px #ffffff,
        1136px 1867px #ffffff, 1280px 1233px #ffffff, 836px 296px #ffffff,
        1348px 646px #ffffff, 1539px 913px #ffffff, 423px 781px #ffffff,
        1271px 1805px #ffffff, 696px 564px #ffffff, 1549px 804px #ffffff,
        303px 1555px #ffffff, 1449px 1903px #ffffff, 66px 687px #ffffff,
        1164px 856px #ffffff, 1958px 1326px #ffffff, 125px 157px #ffffff,
        508px 1669px #ffffff, 465px 725px #ffffff, 1925px 1440px #ffffff,
        405px 793px #ffffff, 278px 110px #ffffff, 1084px 1065px #ffffff,
        1077px 705px #ffffff, 663px 1844px #ffffff, 734px 263px #ffffff,
        870px 1761px #ffffff, 103px 1169px #ffffff, 1506px 1295px #ffffff,
        1883px 926px #ffffff, 335px 1361px #ffffff, 1126px 1284px #ffffff,
        257px 1165px #ffffff, 837px 580px #ffffff, 1211px 1362px #ffffff,
        1137px 1380px #ffffff, 135px 632px #ffffff, 1491px 1965px #ffffff,
        1098px 195px #ffffff, 506px 417px #ffffff, 693px 1243px #ffffff,
        622px 1862px #ffffff, 1412px 1343px #ffffff, 948px 1894px #ffffff,
        1315px 1363px #ffffff, 754px 1098px #ffffff, 1931px 930px #ffffff,
        1831px 342px #ffffff, 1751px 1839px #ffffff, 84px 775px #ffffff,
        1662px 1488px #ffffff, 617px 1769px #ffffff, 1869px 1292px #ffffff,
        963px 432px #ffffff, 371px 1114px #ffffff, 37px 642px #ffffff,
        21px 1184px #ffffff, 602px 366px #ffffff, 414px 524px #ffffff,
        282px 244px #ffffff, 1689px 868px #ffffff, 943px 681px #ffffff,
        898px 679px #ffffff, 449px 1774px #ffffff, 1678px 1313px #ffffff,
        475px 1811px #ffffff, 1146px 1509px #ffffff, 1151px 1863px #ffffff,
        1617px 846px #ffffff, 82px 1077px #ffffff, 324px 1317px #ffffff,
        1516px 885px #ffffff, 1706px 1526px #ffffff, 1925px 1180px #ffffff,
        553px 967px #ffffff, 1072px 536px #ffffff, 1715px 1816px #ffffff,
        185px 286px #ffffff, 1362px 1600px #ffffff, 628px 1938px #ffffff,
        1187px 412px #ffffff, 569px 211px #ffffff, 1959px 1356px #ffffff,
        1571px 105px #ffffff, 319px 1111px #ffffff, 36px 1364px #ffffff,
        502px 1788px #ffffff, 1051px 1993px #ffffff, 1617px 773px #ffffff,
        424px 1507px #ffffff, 1623px 1955px #ffffff, 307px 662px #ffffff,
        183px 1048px #ffffff, 1919px 1453px #ffffff, 1006px 1817px #ffffff,
        468px 673px #ffffff, 1142px 1375px #ffffff, 1228px 443px #ffffff,
        1734px 552px #ffffff, 20px 1041px #ffffff, 1783px 334px #ffffff,
        98px 1237px #ffffff, 1356px 1940px #ffffff, 853px 1779px #ffffff,
        1910px 560px #ffffff, 1174px 1656px #ffffff, 110px 1724px #ffffff,
        542px 1771px #ffffff, 1758px 1931px #ffffff, 1463px 1401px #ffffff,
        1155px 84px #ffffff, 1504px 835px #ffffff, 750px 322px #ffffff,
        407px 1900px #ffffff, 1600px 1141px #ffffff, 657px 886px #ffffff,
        526px 714px #ffffff, 18px 836px #ffffff, 1546px 1548px #ffffff,
        22px 469px #ffffff, 594px 1466px #ffffff, 1160px 1078px #ffffff,
        627px 1055px #ffffff, 195px 699px #ffffff, 1099px 684px #ffffff,
        530px 551px #ffffff, 1160px 1325px #ffffff, 894px 727px #ffffff,
        1157px 98px #ffffff, 136px 1483px #ffffff, 1875px 1975px #ffffff,
        1803px 566px #ffffff, 318px 1073px #ffffff, 1866px 1656px #ffffff,
        543px 414px #ffffff, 719px 474px #ffffff, 1115px 738px #ffffff,
        353px 875px #ffffff, 184px 1938px #ffffff, 1854px 1534px #ffffff,
        420px 1698px #ffffff, 1480px 1550px #ffffff, 522px 203px #ffffff,
        1897px 1904px #ffffff, 975px 1708px #ffffff, 1774px 602px #ffffff,
        1908px 274px #ffffff, 61px 715px #ffffff, 983px 1156px #ffffff,
        326px 1013px #ffffff, 641px 290px #ffffff, 1522px 120px #ffffff,
        405px 1637px #ffffff, 1021px 1099px #ffffff, 631px 1145px #ffffff,
        982px 1967px #ffffff, 200px 651px #ffffff, 795px 351px #ffffff,
        790px 1082px #ffffff, 144px 1572px #ffffff, 1542px 901px #ffffff,
        158px 1524px #ffffff, 849px 1843px #ffffff, 1807px 203px #ffffff,
        1747px 45px #ffffff, 1603px 1738px #ffffff, 617px 1966px #ffffff,
        342px 748px #ffffff, 1779px 1173px #ffffff, 1428px 152px #ffffff,
        589px 1998px #ffffff, 1940px 1838px #ffffff, 115px 272px #ffffff,
        1217px 1395px #ffffff, 1402px 1491px #ffffff, 1833px 1814px #ffffff,
        243px 966px #ffffff, 319px 578px #ffffff, 813px 364px #ffffff,
        669px 882px #ffffff, 551px 134px #ffffff, 1819px 920px #ffffff,
        740px 1826px #ffffff, 1021px 952px #ffffff, 1575px 453px #ffffff,
        324px 419px #ffffff, 929px 417px #ffffff, 885px 1112px #ffffff,
        503px 187px #ffffff, 1908px 362px #ffffff, 1063px 1601px #ffffff,
        169px 1792px #ffffff, 789px 963px #ffffff, 1697px 948px #ffffff,
        1761px 1810px #ffffff, 1844px 1591px #ffffff, 1709px 949px #ffffff,
        1402px 1396px #ffffff, 1037px 225px #ffffff, 1832px 518px #ffffff,
        1728px 1782px #ffffff, 194px 1421px #ffffff, 1395px 742px #ffffff,
        1478px 1325px #ffffff, 40px 593px #ffffff, 1732px 117px #ffffff,
        51px 158px #ffffff, 1598px 1672px #ffffff, 701px 849px #ffffff,
        1403px 1979px #ffffff, 145px 1414px #ffffff, 550px 906px #ffffff,
        1366px 460px #ffffff, 142px 1379px #ffffff, 34px 1864px #ffffff,
        1346px 308px #ffffff, 293px 998px #ffffff, 21px 1868px #ffffff,
        540px 1033px #ffffff, 60px 746px #ffffff, 1602px 1476px #ffffff,
        180px 804px #ffffff, 345px 1982px #ffffff, 1439px 640px #ffffff,
        939px 1834px #ffffff, 20px 432px #ffffff, 492px 1549px #ffffff,
        109px 1579px #ffffff, 1796px 1403px #ffffff, 1079px 519px #ffffff,
        1664px 389px #ffffff, 1627px 1061px #ffffff, 823px 419px #ffffff,
        1399px 1882px #ffffff, 1906px 344px #ffffff, 1189px 848px #ffffff,
        117px 882px #ffffff, 1262px 33px #ffffff, 1048px 434px #ffffff,
        1208px 1309px #ffffff, 1616px 408px #ffffff, 1833px 853px #ffffff,
        1433px 1656px #ffffff, 811px 1861px #ffffff, 439px 1672px #ffffff,
        1105px 248px #ffffff, 328px 1652px #ffffff, 13px 1658px #ffffff,
        685px 987px #ffffff, 985px 403px #ffffff, 1664px 1206px #ffffff,
        1993px 1925px #ffffff, 440px 917px #ffffff, 1835px 319px #ffffff,
        1404px 1907px #ffffff, 624px 1443px #ffffff, 843px 954px #ffffff,
        478px 1567px #ffffff, 895px 1602px #ffffff, 1231px 871px #ffffff,
        1267px 1646px #ffffff, 475px 334px #ffffff, 784px 796px #ffffff,
        1294px 199px #ffffff, 109px 702px #ffffff, 1978px 362px #ffffff,
        291px 940px #ffffff, 971px 1343px #ffffff, 74px 719px #ffffff,
        36px 715px #ffffff, 1007px 1423px #ffffff, 860px 314px #ffffff,
        631px 177px #ffffff, 1900px 1590px #ffffff, 1239px 1348px #ffffff,
        1346px 1270px #ffffff, 1934px 1475px #ffffff, 1553px 559px #ffffff,
        588px 1969px #ffffff, 670px 1269px #ffffff, 1484px 376px #ffffff,
        20px 1424px #ffffff, 1396px 8px #ffffff, 969px 244px #ffffff,
        1807px 538px #ffffff, 1873px 891px #ffffff, 636px 1142px #ffffff,
        1474px 1562px #ffffff, 763px 350px #ffffff, 663px 700px #ffffff,
        500px 1469px #ffffff, 1302px 722px #ffffff, 181px 291px #ffffff,
        266px 893px #ffffff, 1403px 654px #ffffff, 492px 460px #ffffff,
        1503px 1369px #ffffff, 23px 1662px #ffffff, 349px 333px #ffffff,
        1435px 1017px #ffffff, 1441px 705px #ffffff, 1708px 1446px #ffffff,
        1041px 911px #ffffff, 1063px 780px #ffffff, 1158px 1356px #ffffff,
        767px 1454px #ffffff, 1912px 797px #ffffff, 1731px 1759px #ffffff,
        1378px 1390px #ffffff, 1815px 1364px #ffffff, 960px 270px #ffffff,
        1343px 427px #ffffff, 275px 203px #ffffff, 1319px 1092px #ffffff,
        1455px 770px #ffffff, 283px 1503px #ffffff, 1505px 901px #ffffff,
        1738px 1561px #ffffff, 1526px 1935px #ffffff, 1757px 669px #ffffff,
        1640px 620px #ffffff, 1750px 722px #ffffff, 748px 66px #ffffff,
        1149px 540px #ffffff, 159px 953px #ffffff, 200px 1426px #ffffff,
        515px 1110px #ffffff, 1552px 737px #ffffff, 1094px 1459px #ffffff,
        778px 799px #ffffff, 1031px 523px #ffffff, 743px 1825px #ffffff,
        1100px 882px #ffffff, 1088px 1836px #ffffff, 255px 599px #ffffff,
        67px 1361px #ffffff, 247px 1721px #ffffff, 1722px 346px #ffffff,
        1822px 155px #ffffff, 452px 1973px #ffffff, 415px 1960px #ffffff,
        1109px 57px #ffffff, 273px 1392px #ffffff, 404px 1071px #ffffff,
        1212px 353px #ffffff, 370px 460px #ffffff, 795px 1523px #ffffff,
        1932px 340px #ffffff, 51px 1473px #ffffff, 1268px 364px #ffffff,
        1512px 1862px #ffffff, 1678px 1801px #ffffff, 1796px 579px #ffffff,
        254px 251px #ffffff, 1466px 1717px #ffffff, 893px 379px #ffffff,
        1153px 923px #ffffff, 913px 1808px #ffffff, 791px 789px #ffffff,
        417px 1924px #ffffff, 1336px 1599px #ffffff, 1695px 908px #ffffff,
        1120px 114px #ffffff, 493px 1949px #ffffff, 68px 1905px #ffffff,
        969px 481px #ffffff, 1420px 1095px #ffffff, 800px 1117px #ffffff,
        390px 234px #ffffff, 356px 1644px #ffffff, 1098px 1486px #ffffff,
        1360px 521px #ffffff, 149px 1198px #ffffff, 354px 747px #ffffff,
        1749px 487px #ffffff, 470px 76px #ffffff, 1672px 289px #ffffff,
        1731px 545px #ffffff, 1547px 1590px #ffffff, 498px 692px #ffffff,
        398px 1592px #ffffff, 1846px 1237px #ffffff, 1537px 1474px #ffffff,
        1726px 1374px #ffffff, 1922px 858px #ffffff, 376px 321px #ffffff,
        985px 227px #ffffff, 234px 1421px #ffffff, 760px 745px #ffffff,
        1990px 1132px #ffffff, 1560px 1597px #ffffff, 338px 1310px #ffffff,
        1924px 1664px #ffffff, 547px 1747px #ffffff, 1639px 1282px #ffffff,
        1202px 337px #ffffff, 1985px 779px #ffffff, 737px 456px #ffffff,
        89px 501px #ffffff, 963px 792px #ffffff, 655px 1447px #ffffff,
        1492px 1994px #ffffff, 1171px 254px #ffffff, 892px 827px #ffffff,
        1735px 442px #ffffff, 1474px 1187px #ffffff, 846px 1518px #ffffff,
        557px 1805px #ffffff, 738px 945px #ffffff, 795px 68px #ffffff,
        663px 1956px #ffffff, 1607px 290px #ffffff, 1524px 15px #ffffff,
        1097px 1911px #ffffff, 157px 1939px #ffffff, 935px 1065px #ffffff,
        1809px 1708px #ffffff, 164px 1157px #ffffff, 83px 855px #ffffff,
        625px 501px #ffffff, 814px 398px #ffffff, 552px 695px #ffffff,
        597px 1546px #ffffff, 1237px 1417px #ffffff, 628px 284px #ffffff,
        866px 767px #ffffff, 1403px 1394px #ffffff, 765px 1563px #ffffff,
        1648px 109px #ffffff, 1205px 1659px #ffffff, 921px 1313px #ffffff,
        1319px 243px #ffffff, 18px 125px #ffffff, 7px 777px #ffffff,
        181px 418px #ffffff, 1062px 1892px #ffffff, 382px 106px #ffffff,
        994px 751px #ffffff, 964px 234px #ffffff, 40px 118px #ffffff,
        278px 706px #ffffff, 1540px 1978px #ffffff, 425px 1661px #ffffff,
        1050px 321px #ffffff, 735px 1729px #ffffff, 1438px 260px #ffffff,
        1229px 1109px #ffffff, 186px 1041px #ffffff, 244px 1184px #ffffff,
        392px 1472px #ffffff, 670px 1249px #ffffff, 1260px 1443px #ffffff,
        1977px 1511px #ffffff, 1240px 773px #ffffff, 303px 513px #ffffff,
        63px 1530px #ffffff, 610px 792px #ffffff, 1987px 1647px #ffffff,
        676px 1597px #ffffff, 1740px 1244px #ffffff, 816px 1661px #ffffff,
        351px 802px #ffffff, 252px 1082px #ffffff, 31px 365px #ffffff,
        1453px 984px #ffffff, 667px 1233px #ffffff, 1247px 1800px #ffffff,
        839px 270px #ffffff, 775px 913px #ffffff, 1966px 1398px #ffffff,
        499px 813px #ffffff, 922px 1982px #ffffff, 1409px 1902px #ffffff,
        1499px 1766px #ffffff, 721px 899px #ffffff, 788px 807px #ffffff,
        989px 1355px #ffffff, 1248px 1274px #ffffff, 849px 1091px #ffffff,
        1799px 1036px #ffffff, 1486px 700px #ffffff, 170px 1989px #ffffff,
        1275px 799px #ffffff, 772px 2000px #ffffff, 1642px 362px #ffffff,
        216px 940px #ffffff, 1893px 281px #ffffff, 1944px 1298px #ffffff,
        1294px 400px #ffffff, 1523px 441px #ffffff, 1829px 340px #ffffff,
        468px 170px #ffffff, 1099px 967px #ffffff, 1331px 665px #ffffff,
        1174px 1553px #ffffff, 1567px 325px #ffffff, 1028px 1399px #ffffff,
        781px 1451px #ffffff, 1912px 1954px #ffffff, 874px 873px #ffffff,
        1298px 1722px #ffffff, 1879px 706px #ffffff, 57px 1221px #ffffff,
        1116px 1432px #ffffff, 48px 811px #ffffff, 101px 916px #ffffff,
        677px 304px #ffffff, 1203px 639px #ffffff, 1391px 199px #ffffff,
        1895px 1988px #ffffff, 1462px 1023px #ffffff, 1216px 1751px #ffffff,
        1261px 663px #ffffff, 1290px 1119px #ffffff, 137px 1793px #ffffff,
        1052px 1470px #ffffff, 1561px 226px #ffffff, 1156px 402px #ffffff,
        709px 693px #ffffff, 1040px 1911px #ffffff, 1624px 1115px #ffffff,
        551px 475px #ffffff, 416px 1090px #ffffff, 1183px 451px #ffffff,
        58px 765px #ffffff, 743px 1016px #ffffff, 198px 369px #ffffff,
        1645px 1503px #ffffff, 997px 22px #ffffff, 1447px 1323px #ffffff,
        379px 883px #ffffff, 1171px 1195px #ffffff, 919px 133px #ffffff,
        1400px 517px #ffffff, 725px 804px #ffffff, 1600px 699px #ffffff,
        357px 581px #ffffff, 266px 1713px #ffffff, 848px 1749px #ffffff,
        1963px 1045px #ffffff, 119px 1136px #ffffff;
    }
  }

  #stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1117px 1306px #ffffff, 1078px 1783px #ffffff,
      1179px 1085px #ffffff, 1145px 920px #ffffff, 422px 1233px #ffffff,
      387px 98px #ffffff, 1153px 637px #ffffff, 1084px 782px #ffffff,
      476px 453px #ffffff, 926px 1306px #ffffff, 60px 1086px #ffffff,
      753px 1575px #ffffff, 272px 1684px #ffffff, 1285px 750px #ffffff,
      1416px 1327px #ffffff, 1931px 473px #ffffff, 736px 1395px #ffffff,
      1816px 763px #ffffff, 438px 879px #ffffff, 665px 1902px #ffffff,
      1341px 677px #ffffff, 1404px 1073px #ffffff, 100px 597px #ffffff,
      357px 1689px #ffffff, 1044px 1342px #ffffff, 1954px 502px #ffffff,
      1192px 1308px #ffffff, 540px 1239px #ffffff, 1360px 552px #ffffff,
      89px 752px #ffffff, 659px 1253px #ffffff, 62px 517px #ffffff,
      1375px 1705px #ffffff, 1343px 1511px #ffffff, 1659px 1922px #ffffff,
      1560px 289px #ffffff, 1362px 1799px #ffffff, 1886px 1480px #ffffff,
      1718px 1885px #ffffff, 824px 738px #ffffff, 1060px 1370px #ffffff,
      1781px 1171px #ffffff, 255px 273px #ffffff, 1197px 120px #ffffff,
      213px 7px #ffffff, 1226px 1920px #ffffff, 1844px 207px #ffffff,
      1675px 970px #ffffff, 1435px 1283px #ffffff, 37px 353px #ffffff,
      59px 417px #ffffff, 921px 1602px #ffffff, 1549px 1490px #ffffff,
      638px 1845px #ffffff, 1328px 198px #ffffff, 1050px 1149px #ffffff,
      1884px 711px #ffffff, 333px 263px #ffffff, 342px 1508px #ffffff,
      1388px 1810px #ffffff, 1377px 1558px #ffffff, 890px 487px #ffffff,
      1081px 759px #ffffff, 890px 1515px #ffffff, 911px 1284px #ffffff,
      335px 735px #ffffff, 1140px 549px #ffffff, 1239px 1064px #ffffff,
      226px 71px #ffffff, 1100px 1278px #ffffff, 1851px 1805px #ffffff,
      1370px 1999px #ffffff, 1008px 1122px #ffffff, 785px 813px #ffffff,
      1358px 601px #ffffff, 1833px 1305px #ffffff, 1768px 1304px #ffffff,
      1303px 532px #ffffff, 860px 598px #ffffff, 1329px 593px #ffffff,
      1038px 1088px #ffffff, 408px 405px #ffffff, 965px 82px #ffffff,
      1483px 1438px #ffffff, 310px 1479px #ffffff, 1786px 1500px #ffffff,
      1866px 852px #ffffff, 18px 1757px #ffffff, 1473px 1004px #ffffff,
      1542px 1933px #ffffff, 633px 1970px #ffffff, 1334px 1713px #ffffff,
      175px 28px #ffffff, 592px 894px #ffffff, 121px 1162px #ffffff,
      1601px 1567px #ffffff, 1095px 657px #ffffff, 640px 1233px #ffffff,
      1073px 1255px #ffffff, 840px 1087px #ffffff, 718px 250px #ffffff,
      967px 709px #ffffff, 731px 239px #ffffff, 1623px 593px #ffffff,
      1058px 1820px #ffffff, 516px 1898px #ffffff, 666px 12px #ffffff,
      1997px 1382px #ffffff, 112px 1690px #ffffff, 687px 1309px #ffffff,
      63px 539px #ffffff, 185px 1897px #ffffff, 1055px 1691px #ffffff,
      435px 1517px #ffffff, 1175px 1119px #ffffff, 1721px 133px #ffffff,
      1212px 47px #ffffff, 166px 18px #ffffff, 1416px 1652px #ffffff,
      1409px 1745px #ffffff, 1357px 1232px #ffffff, 1677px 1998px #ffffff,
      448px 1415px #ffffff, 705px 1736px #ffffff, 1031px 1466px #ffffff,
      543px 1651px #ffffff, 1592px 1888px #ffffff, 1749px 1175px #ffffff,
      639px 1114px #ffffff, 1591px 508px #ffffff, 759px 1244px #ffffff,
      824px 380px #ffffff, 942px 955px #ffffff, 723px 732px #ffffff,
      113px 1369px #ffffff, 203px 1739px #ffffff, 868px 733px #ffffff,
      713px 971px #ffffff, 341px 833px #ffffff, 762px 824px #ffffff,
      1359px 310px #ffffff, 1858px 1349px #ffffff, 1531px 692px #ffffff,
      1075px 1512px #ffffff, 1677px 142px #ffffff, 1912px 1478px #ffffff,
      1810px 1078px #ffffff, 426px 844px #ffffff, 1426px 588px #ffffff,
      1909px 654px #ffffff, 1107px 295px #ffffff, 1351px 527px #ffffff,
      1393px 599px #ffffff, 1379px 1068px #ffffff, 228px 1846px #ffffff,
      1271px 374px #ffffff, 1348px 612px #ffffff, 7px 1301px #ffffff,
      1501px 1782px #ffffff, 1795px 423px #ffffff, 1475px 1918px #ffffff,
      1328px 1861px #ffffff, 1624px 51px #ffffff, 1791px 672px #ffffff,
      1594px 1467px #ffffff, 1655px 1603px #ffffff, 919px 850px #ffffff,
      523px 609px #ffffff, 1196px 207px #ffffff, 753px 410px #ffffff,
      686px 1097px #ffffff, 1570px 133px #ffffff, 1996px 1137px #ffffff,
      361px 116px #ffffff, 1015px 462px #ffffff, 76px 1143px #ffffff,
      491px 1818px #ffffff, 1563px 795px #ffffff, 982px 1721px #ffffff,
      831px 1204px #ffffff, 1737px 589px #ffffff, 861px 1579px #ffffff,
      1666px 130px #ffffff, 698px 1799px #ffffff, 726px 1519px #ffffff,
      109px 1208px #ffffff, 1184px 1057px #ffffff, 835px 451px #ffffff,
      896px 594px #ffffff, 35px 893px #ffffff, 895px 542px #ffffff,
      706px 225px #ffffff, 56px 1040px #ffffff, 1954px 108px #ffffff,
      1439px 1423px #ffffff, 26px 1881px #ffffff, 802px 1564px #ffffff,
      273px 708px #ffffff, 40px 31px #ffffff, 859px 108px #ffffff;

    animation: animStar 100s linear infinite;

    &::after {
      content: " ";
      position: absolute;
      top: 0px;
      width: 2px;
      height: 2px;
      background: transparent;
      box-shadow: 1117px 1306px #ffffff, 1078px 1783px #ffffff,
        1179px 1085px #ffffff, 1145px 920px #ffffff, 422px 1233px #ffffff,
        387px 98px #ffffff, 1153px 637px #ffffff, 1084px 782px #ffffff,
        476px 453px #ffffff, 926px 1306px #ffffff, 60px 1086px #ffffff,
        753px 1575px #ffffff, 272px 1684px #ffffff, 1285px 750px #ffffff,
        1416px 1327px #ffffff, 1931px 473px #ffffff, 736px 1395px #ffffff,
        1816px 763px #ffffff, 438px 879px #ffffff, 665px 1902px #ffffff,
        1341px 677px #ffffff, 1404px 1073px #ffffff, 100px 597px #ffffff,
        357px 1689px #ffffff, 1044px 1342px #ffffff, 1954px 502px #ffffff,
        1192px 1308px #ffffff, 540px 1239px #ffffff, 1360px 552px #ffffff,
        89px 752px #ffffff, 659px 1253px #ffffff, 62px 517px #ffffff,
        1375px 1705px #ffffff, 1343px 1511px #ffffff, 1659px 1922px #ffffff,
        1560px 289px #ffffff, 1362px 1799px #ffffff, 1886px 1480px #ffffff,
        1718px 1885px #ffffff, 824px 738px #ffffff, 1060px 1370px #ffffff,
        1781px 1171px #ffffff, 255px 273px #ffffff, 1197px 120px #ffffff,
        213px 7px #ffffff, 1226px 1920px #ffffff, 1844px 207px #ffffff,
        1675px 970px #ffffff, 1435px 1283px #ffffff, 37px 353px #ffffff,
        59px 417px #ffffff, 921px 1602px #ffffff, 1549px 1490px #ffffff,
        638px 1845px #ffffff, 1328px 198px #ffffff, 1050px 1149px #ffffff,
        1884px 711px #ffffff, 333px 263px #ffffff, 342px 1508px #ffffff,
        1388px 1810px #ffffff, 1377px 1558px #ffffff, 890px 487px #ffffff,
        1081px 759px #ffffff, 890px 1515px #ffffff, 911px 1284px #ffffff,
        335px 735px #ffffff, 1140px 549px #ffffff, 1239px 1064px #ffffff,
        226px 71px #ffffff, 1100px 1278px #ffffff, 1851px 1805px #ffffff,
        1370px 1999px #ffffff, 1008px 1122px #ffffff, 785px 813px #ffffff,
        1358px 601px #ffffff, 1833px 1305px #ffffff, 1768px 1304px #ffffff,
        1303px 532px #ffffff, 860px 598px #ffffff, 1329px 593px #ffffff,
        1038px 1088px #ffffff, 408px 405px #ffffff, 965px 82px #ffffff,
        1483px 1438px #ffffff, 310px 1479px #ffffff, 1786px 1500px #ffffff,
        1866px 852px #ffffff, 18px 1757px #ffffff, 1473px 1004px #ffffff,
        1542px 1933px #ffffff, 633px 1970px #ffffff, 1334px 1713px #ffffff,
        175px 28px #ffffff, 592px 894px #ffffff, 121px 1162px #ffffff,
        1601px 1567px #ffffff, 1095px 657px #ffffff, 640px 1233px #ffffff,
        1073px 1255px #ffffff, 840px 1087px #ffffff, 718px 250px #ffffff,
        967px 709px #ffffff, 731px 239px #ffffff, 1623px 593px #ffffff,
        1058px 1820px #ffffff, 516px 1898px #ffffff, 666px 12px #ffffff,
        1997px 1382px #ffffff, 112px 1690px #ffffff, 687px 1309px #ffffff,
        63px 539px #ffffff, 185px 1897px #ffffff, 1055px 1691px #ffffff,
        435px 1517px #ffffff, 1175px 1119px #ffffff, 1721px 133px #ffffff,
        1212px 47px #ffffff, 166px 18px #ffffff, 1416px 1652px #ffffff,
        1409px 1745px #ffffff, 1357px 1232px #ffffff, 1677px 1998px #ffffff,
        448px 1415px #ffffff, 705px 1736px #ffffff, 1031px 1466px #ffffff,
        543px 1651px #ffffff, 1592px 1888px #ffffff, 1749px 1175px #ffffff,
        639px 1114px #ffffff, 1591px 508px #ffffff, 759px 1244px #ffffff,
        824px 380px #ffffff, 942px 955px #ffffff, 723px 732px #ffffff,
        113px 1369px #ffffff, 203px 1739px #ffffff, 868px 733px #ffffff,
        713px 971px #ffffff, 341px 833px #ffffff, 762px 824px #ffffff,
        1359px 310px #ffffff, 1858px 1349px #ffffff, 1531px 692px #ffffff,
        1075px 1512px #ffffff, 1677px 142px #ffffff, 1912px 1478px #ffffff,
        1810px 1078px #ffffff, 426px 844px #ffffff, 1426px 588px #ffffff,
        1909px 654px #ffffff, 1107px 295px #ffffff, 1351px 527px #ffffff,
        1393px 599px #ffffff, 1379px 1068px #ffffff, 228px 1846px #ffffff,
        1271px 374px #ffffff, 1348px 612px #ffffff, 7px 1301px #ffffff,
        1501px 1782px #ffffff, 1795px 423px #ffffff, 1475px 1918px #ffffff,
        1328px 1861px #ffffff, 1624px 51px #ffffff, 1791px 672px #ffffff,
        1594px 1467px #ffffff, 1655px 1603px #ffffff, 919px 850px #ffffff,
        523px 609px #ffffff, 1196px 207px #ffffff, 753px 410px #ffffff,
        686px 1097px #ffffff, 1570px 133px #ffffff, 1996px 1137px #ffffff,
        361px 116px #ffffff, 1015px 462px #ffffff, 76px 1143px #ffffff,
        491px 1818px #ffffff, 1563px 795px #ffffff, 982px 1721px #ffffff,
        831px 1204px #ffffff, 1737px 589px #ffffff, 861px 1579px #ffffff,
        1666px 130px #ffffff, 698px 1799px #ffffff, 726px 1519px #ffffff,
        109px 1208px #ffffff, 1184px 1057px #ffffff, 835px 451px #ffffff,
        896px 594px #ffffff, 35px 893px #ffffff, 895px 542px #ffffff,
        706px 225px #ffffff, 56px 1040px #ffffff, 1954px 108px #ffffff,
        1439px 1423px #ffffff, 26px 1881px #ffffff, 802px 1564px #ffffff,
        273px 708px #ffffff, 40px 31px #ffffff, 859px 108px #ffffff;
    }
  }

  #stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 940px 1360px #ffffff, 1071px 539px #ffffff,
      1710px 1414px #ffffff, 836px 299px #ffffff, 1944px 1420px #ffffff,
      253px 1449px #ffffff, 1257px 1250px #ffffff, 1588px 1830px #ffffff,
      1077px 1204px #ffffff, 273px 1081px #ffffff, 1993px 766px #ffffff,
      1808px 479px #ffffff, 917px 263px #ffffff, 663px 1820px #ffffff,
      342px 1988px #ffffff, 727px 1250px #ffffff, 636px 1666px #ffffff,
      692px 1112px #ffffff, 248px 1211px #ffffff, 1422px 1121px #ffffff,
      881px 46px #ffffff, 1531px 1977px #ffffff, 1643px 1023px #ffffff,
      684px 1071px #ffffff, 1142px 1873px #ffffff, 292px 1313px #ffffff,
      256px 1237px #ffffff, 89px 912px #ffffff, 964px 1783px #ffffff,
      877px 760px #ffffff, 1641px 1474px #ffffff, 1492px 24px #ffffff,
      1776px 1642px #ffffff, 183px 602px #ffffff, 1998px 62px #ffffff,
      1560px 367px #ffffff, 1333px 995px #ffffff, 704px 1815px #ffffff,
      1809px 712px #ffffff, 1503px 288px #ffffff, 630px 556px #ffffff,
      1715px 125px #ffffff, 353px 1878px #ffffff, 975px 333px #ffffff,
      1740px 1409px #ffffff, 1341px 1871px #ffffff, 1279px 1064px #ffffff,
      169px 874px #ffffff, 161px 528px #ffffff, 1671px 1669px #ffffff,
      169px 632px #ffffff, 547px 1724px #ffffff, 1904px 110px #ffffff,
      679px 1670px #ffffff, 196px 123px #ffffff, 786px 871px #ffffff,
      1840px 324px #ffffff, 356px 967px #ffffff, 61px 549px #ffffff,
      99px 677px #ffffff, 1719px 87px #ffffff, 1713px 1990px #ffffff,
      1717px 1358px #ffffff, 108px 1187px #ffffff, 51px 869px #ffffff,
      1461px 902px #ffffff, 1034px 891px #ffffff, 962px 1881px #ffffff,
      1723px 595px #ffffff, 479px 901px #ffffff, 1546px 1823px #ffffff,
      285px 1208px #ffffff, 1056px 347px #ffffff, 261px 988px #ffffff,
      466px 990px #ffffff, 1657px 648px #ffffff, 1249px 933px #ffffff,
      1552px 1555px #ffffff, 147px 62px #ffffff, 292px 1157px #ffffff,
      1816px 423px #ffffff, 1714px 757px #ffffff, 1036px 961px #ffffff,
      1955px 710px #ffffff, 1842px 516px #ffffff, 479px 1870px #ffffff,
      1579px 1445px #ffffff, 1225px 1309px #ffffff, 1965px 566px #ffffff,
      1575px 1072px #ffffff, 923px 329px #ffffff, 651px 1514px #ffffff,
      865px 1100px #ffffff, 782px 1873px #ffffff, 115px 299px #ffffff,
      14px 1668px #ffffff, 1666px 1817px #ffffff, 1096px 1068px #ffffff,
      1462px 742px #ffffff, 1384px 1750px #ffffff;

    animation: animStar 150s linear infinite;

    &::after {
      content: " ";
      position: absolute;
      top: 0px;
      width: 3px;
      height: 3px;
      background: transparent;
      box-shadow: 940px 1360px #ffffff, 1071px 539px #ffffff,
        1710px 1414px #ffffff, 836px 299px #ffffff, 1944px 1420px #ffffff,
        253px 1449px #ffffff, 1257px 1250px #ffffff, 1588px 1830px #ffffff,
        1077px 1204px #ffffff, 273px 1081px #ffffff, 1993px 766px #ffffff,
        1808px 479px #ffffff, 917px 263px #ffffff, 663px 1820px #ffffff,
        342px 1988px #ffffff, 727px 1250px #ffffff, 636px 1666px #ffffff,
        692px 1112px #ffffff, 248px 1211px #ffffff, 1422px 1121px #ffffff,
        881px 46px #ffffff, 1531px 1977px #ffffff, 1643px 1023px #ffffff,
        684px 1071px #ffffff, 1142px 1873px #ffffff, 292px 1313px #ffffff,
        256px 1237px #ffffff, 89px 912px #ffffff, 964px 1783px #ffffff,
        877px 760px #ffffff, 1641px 1474px #ffffff, 1492px 24px #ffffff,
        1776px 1642px #ffffff, 183px 602px #ffffff, 1998px 62px #ffffff,
        1560px 367px #ffffff, 1333px 995px #ffffff, 704px 1815px #ffffff,
        1809px 712px #ffffff, 1503px 288px #ffffff, 630px 556px #ffffff,
        1715px 125px #ffffff, 353px 1878px #ffffff, 975px 333px #ffffff,
        1740px 1409px #ffffff, 1341px 1871px #ffffff, 1279px 1064px #ffffff,
        169px 874px #ffffff, 161px 528px #ffffff, 1671px 1669px #ffffff,
        169px 632px #ffffff, 547px 1724px #ffffff, 1904px 110px #ffffff,
        679px 1670px #ffffff, 196px 123px #ffffff, 786px 871px #ffffff,
        1840px 324px #ffffff, 356px 967px #ffffff, 61px 549px #ffffff,
        99px 677px #ffffff, 1719px 87px #ffffff, 1713px 1990px #ffffff,
        1717px 1358px #ffffff, 108px 1187px #ffffff, 51px 869px #ffffff,
        1461px 902px #ffffff, 1034px 891px #ffffff, 962px 1881px #ffffff,
        1723px 595px #ffffff, 479px 901px #ffffff, 1546px 1823px #ffffff,
        285px 1208px #ffffff, 1056px 347px #ffffff, 261px 988px #ffffff,
        466px 990px #ffffff, 1657px 648px #ffffff, 1249px 933px #ffffff,
        1552px 1555px #ffffff, 147px 62px #ffffff, 292px 1157px #ffffff,
        1816px 423px #ffffff, 1714px 757px #ffffff, 1036px 961px #ffffff,
        1955px 710px #ffffff, 1842px 516px #ffffff, 479px 1870px #ffffff,
        1579px 1445px #ffffff, 1225px 1309px #ffffff, 1965px 566px #ffffff,
        1575px 1072px #ffffff, 923px 329px #ffffff, 651px 1514px #ffffff,
        865px 1100px #ffffff, 782px 1873px #ffffff, 115px 299px #ffffff,
        14px 1668px #ffffff, 1666px 1817px #ffffff, 1096px 1068px #ffffff,
        1462px 742px #ffffff, 1384px 1750px #ffffff;
    }
  }

  #stars4 {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 233px 1976px #ffffff, 1196px 1119px #ffffff, 646px 740px #ffffff,
      335px 645px #ffffff, 1119px 1452px #ffffff, 176px 1870px #ffffff,
      639px 1711px #ffffff, 647px 1388px #ffffff, 1516px 1108px #ffffff,
      464px 66px #ffffff, 331px 344px #ffffff, 772px 1189px #ffffff,
      1516px 1850px #ffffff, 1500px 1463px #ffffff, 1275px 876px #ffffff,
      1107px 645px #ffffff, 977px 478px #ffffff, 583px 1179px #ffffff,
      284px 395px #ffffff, 1220px 461px #ffffff, 1160px 249px #ffffff,
      196px 865px #ffffff, 670px 1915px #ffffff, 1449px 382px #ffffff,
      1191px 546px #ffffff, 1329px 605px #ffffff, 1945px 458px #ffffff,
      995px 749px #ffffff, 1495px 861px #ffffff, 708px 1731px #ffffff,
      348px 653px #ffffff, 548px 1298px #ffffff, 1606px 990px #ffffff,
      1049px 1204px #ffffff, 253px 1501px #ffffff, 1154px 166px #ffffff,
      1087px 104px #ffffff, 1034px 1161px #ffffff, 1681px 462px #ffffff,
      577px 1897px #ffffff, 193px 1901px #ffffff, 1701px 1755px #ffffff,
      864px 1297px #ffffff, 800px 1289px #ffffff, 676px 28px #ffffff,
      185px 1341px #ffffff, 379px 1151px #ffffff, 1224px 1725px #ffffff,
      280px 541px #ffffff, 473px 1196px #ffffff, 921px 1628px #ffffff,
      969px 432px #ffffff, 1475px 758px #ffffff, 1195px 993px #ffffff,
      876px 1840px #ffffff, 1274px 1689px #ffffff, 1977px 1101px #ffffff,
      837px 527px #ffffff, 1785px 1610px #ffffff, 1650px 1843px #ffffff,
      1127px 1508px #ffffff, 401px 1050px #ffffff, 51px 1105px #ffffff,
      545px 880px #ffffff, 1786px 1672px #ffffff, 318px 260px #ffffff,
      568px 254px #ffffff, 1026px 1527px #ffffff, 1242px 852px #ffffff,
      1785px 982px #ffffff, 1318px 1071px #ffffff, 398px 1061px #ffffff,
      1509px 257px #ffffff, 599px 928px #ffffff, 1195px 1800px #ffffff,
      1254px 906px #ffffff, 141px 26px #ffffff, 1384px 1502px #ffffff,
      476px 767px #ffffff, 1973px 722px #ffffff, 1339px 1031px #ffffff,
      778px 818px #ffffff, 213px 1320px #ffffff, 184px 221px #ffffff,
      983px 1911px #ffffff, 923px 1439px #ffffff, 1936px 581px #ffffff,
      1105px 625px #ffffff, 325px 729px #ffffff, 1475px 204px #ffffff,
      1483px 1564px #ffffff, 1327px 1272px #ffffff, 1187px 1944px #ffffff,
      1945px 1471px #ffffff, 116px 960px #ffffff, 1660px 1610px #ffffff,
      412px 1022px #ffffff, 1552px 1516px #ffffff, 1517px 1892px #ffffff,
      306px 829px #ffffff, 1416px 462px #ffffff, 1575px 1460px #ffffff,
      424px 1500px #ffffff, 1530px 1169px #ffffff, 1388px 1608px #ffffff,
      185px 416px #ffffff, 634px 1446px #ffffff, 767px 479px #ffffff,
      71px 426px #ffffff, 1937px 145px #ffffff, 1955px 1312px #ffffff,
      1811px 611px #ffffff, 1145px 569px #ffffff, 1460px 676px #ffffff,
      131px 1858px #ffffff, 1557px 473px #ffffff, 735px 130px #ffffff,
      112px 1531px #ffffff, 1312px 305px #ffffff, 409px 1032px #ffffff,
      149px 1964px #ffffff, 535px 1215px #ffffff, 1382px 630px #ffffff,
      1437px 1368px #ffffff, 362px 1181px #ffffff, 388px 181px #ffffff,
      274px 1287px #ffffff, 1858px 1414px #ffffff, 661px 1935px #ffffff,
      675px 1205px #ffffff, 1829px 1725px #ffffff, 1937px 1145px #ffffff,
      237px 908px #ffffff, 1059px 1185px #ffffff, 824px 1248px #ffffff,
      1167px 1730px #ffffff, 180px 1961px #ffffff, 1663px 203px #ffffff,
      374px 221px #ffffff, 724px 1883px #ffffff, 970px 1362px #ffffff,
      832px 505px #ffffff, 313px 233px #ffffff, 1909px 597px #ffffff,
      434px 201px #ffffff, 587px 995px #ffffff, 1833px 623px #ffffff,
      1464px 561px #ffffff, 231px 593px #ffffff, 1558px 1433px #ffffff,
      1986px 1767px #ffffff, 1753px 1728px #ffffff, 1153px 1623px #ffffff,
      249px 229px #ffffff, 1503px 1186px #ffffff, 1784px 137px #ffffff,
      841px 403px #ffffff, 1400px 354px #ffffff, 197px 499px #ffffff,
      1188px 681px #ffffff, 158px 391px #ffffff, 443px 1099px #ffffff,
      723px 1445px #ffffff, 1408px 1235px #ffffff, 1908px 195px #ffffff,
      271px 891px #ffffff, 469px 1693px #ffffff, 580px 11px #ffffff,
      1533px 70px #ffffff, 859px 761px #ffffff, 1510px 1844px #ffffff,
      421px 558px #ffffff, 1132px 1453px #ffffff, 757px 1987px #ffffff,
      212px 293px #ffffff, 569px 323px #ffffff, 1404px 1394px #ffffff,
      252px 1386px #ffffff, 1668px 1857px #ffffff, 123px 1684px #ffffff,
      105px 490px #ffffff, 1083px 1769px #ffffff, 1071px 1953px #ffffff,
      1271px 1159px #ffffff, 699px 1491px #ffffff, 1744px 1997px #ffffff,
      1868px 1973px #ffffff, 1438px 1449px #ffffff, 1222px 1921px #ffffff,
      1328px 1210px #ffffff, 438px 873px #ffffff, 809px 780px #ffffff,
      491px 1524px #ffffff, 447px 1830px #ffffff, 927px 1936px #ffffff,
      564px 691px #ffffff, 1784px 1747px #ffffff, 1978px 1722px #ffffff,
      1599px 1480px #ffffff, 1276px 729px #ffffff, 731px 1174px #ffffff,
      1586px 1711px #ffffff, 451px 1340px #ffffff, 1075px 1899px #ffffff,
      13px 575px #ffffff, 309px 1340px #ffffff, 981px 183px #ffffff,
      248px 1315px #ffffff, 849px 80px #ffffff, 1754px 1540px #ffffff,
      73px 1432px #ffffff, 1208px 1828px #ffffff, 65px 575px #ffffff,
      1098px 730px #ffffff, 127px 1358px #ffffff, 185px 19px #ffffff,
      1222px 1679px #ffffff, 1122px 315px #ffffff, 1906px 452px #ffffff,
      761px 284px #ffffff, 813px 492px #ffffff, 1344px 843px #ffffff,
      118px 1834px #ffffff, 1620px 359px #ffffff, 1755px 1246px #ffffff,
      299px 1076px #ffffff, 1746px 158px #ffffff, 6px 1635px #ffffff,
      143px 190px #ffffff, 101px 468px #ffffff, 137px 971px #ffffff,
      1221px 1929px #ffffff, 1752px 650px #ffffff, 1635px 1761px #ffffff,
      1522px 833px #ffffff, 908px 153px #ffffff, 1044px 350px #ffffff,
      1151px 1940px #ffffff, 822px 210px #ffffff, 1774px 310px #ffffff,
      796px 1447px #ffffff, 1069px 1903px #ffffff, 217px 565px #ffffff,
      662px 1370px #ffffff, 1876px 1570px #ffffff, 847px 46px #ffffff,
      1042px 1689px #ffffff, 1584px 1434px #ffffff, 1791px 908px #ffffff,
      973px 908px #ffffff, 793px 747px #ffffff, 122px 483px #ffffff,
      1137px 1374px #ffffff, 1757px 1791px #ffffff, 513px 225px #ffffff,
      63px 731px #ffffff, 1179px 1926px #ffffff, 346px 18px #ffffff,
      589px 175px #ffffff, 87px 302px #ffffff, 380px 1295px #ffffff,
      450px 921px #ffffff, 1667px 1973px #ffffff, 1495px 1373px #ffffff,
      1462px 1850px #ffffff, 540px 288px #ffffff, 1208px 1051px #ffffff,
      1554px 1095px #ffffff, 1009px 1516px #ffffff, 181px 572px #ffffff,
      165px 387px #ffffff, 549px 1835px #ffffff, 960px 16px #ffffff,
      1360px 403px #ffffff, 1251px 43px #ffffff, 1905px 1813px #ffffff,
      1106px 866px #ffffff, 1809px 277px #ffffff, 1828px 1720px #ffffff,
      295px 1610px #ffffff, 523px 166px #ffffff, 1069px 692px #ffffff,
      1292px 217px #ffffff, 11px 1721px #ffffff, 99px 1045px #ffffff,
      51px 1584px #ffffff, 1053px 266px #ffffff, 1287px 1235px #ffffff,
      747px 1722px #ffffff, 1542px 736px #ffffff, 1256px 18px #ffffff,
      102px 609px #ffffff, 586px 1339px #ffffff, 1843px 1697px #ffffff,
      824px 1687px #ffffff, 1124px 882px #ffffff, 395px 501px #ffffff,
      1456px 672px #ffffff, 1472px 1648px #ffffff, 1326px 1164px #ffffff,
      777px 1672px #ffffff, 81px 345px #ffffff, 91px 386px #ffffff,
      243px 411px #ffffff, 1560px 90px #ffffff, 6px 1771px #ffffff,
      1601px 616px #ffffff, 1220px 1808px #ffffff, 1160px 836px #ffffff,
      246px 1777px #ffffff, 456px 863px #ffffff, 97px 1138px #ffffff,
      1811px 942px #ffffff, 213px 414px #ffffff, 891px 392px #ffffff,
      1044px 927px #ffffff, 1856px 216px #ffffff, 957px 347px #ffffff,
      1486px 406px #ffffff, 838px 912px #ffffff, 803px 361px #ffffff,
      564px 826px #ffffff, 1597px 949px #ffffff, 1206px 289px #ffffff,
      33px 1035px #ffffff, 1762px 1377px #ffffff, 789px 1815px #ffffff,
      1594px 1342px #ffffff, 1668px 880px #ffffff, 1539px 1581px #ffffff,
      1547px 53px #ffffff, 861px 1433px #ffffff, 693px 1618px #ffffff,
      1762px 782px #ffffff, 1568px 682px #ffffff, 1126px 1762px #ffffff,
      1242px 134px #ffffff, 495px 959px #ffffff, 1606px 219px #ffffff,
      1878px 1415px #ffffff, 1652px 797px #ffffff, 782px 1903px #ffffff,
      1774px 1133px #ffffff, 1430px 408px #ffffff, 265px 394px #ffffff,
      890px 336px #ffffff, 1051px 311px #ffffff, 461px 1559px #ffffff,
      1931px 91px #ffffff, 1160px 380px #ffffff, 1442px 1058px #ffffff,
      1157px 364px #ffffff, 586px 227px #ffffff, 1365px 715px #ffffff,
      1658px 1655px #ffffff, 1923px 1664px #ffffff, 1023px 1844px #ffffff,
      1939px 1367px #ffffff, 1203px 1305px #ffffff, 359px 642px #ffffff,
      1056px 425px #ffffff, 787px 202px #ffffff, 1609px 1850px #ffffff,
      1964px 200px #ffffff, 1537px 586px #ffffff, 1589px 903px #ffffff,
      1063px 1694px #ffffff, 760px 1185px #ffffff, 597px 1396px #ffffff,
      294px 452px #ffffff, 433px 818px #ffffff, 199px 840px #ffffff,
      1332px 1937px #ffffff, 169px 1907px #ffffff, 591px 834px #ffffff,
      1716px 1032px #ffffff, 45px 1879px #ffffff, 686px 1469px #ffffff,
      1520px 475px #ffffff, 1122px 859px #ffffff, 973px 1541px #ffffff,
      269px 477px #ffffff, 1390px 716px #ffffff, 1791px 783px #ffffff,
      824px 2000px #ffffff, 1211px 1717px #ffffff, 1008px 1587px #ffffff,
      1422px 204px #ffffff, 234px 556px #ffffff, 506px 550px #ffffff,
      942px 1670px #ffffff, 397px 853px #ffffff, 599px 795px #ffffff,
      762px 1926px #ffffff, 1202px 1424px #ffffff, 135px 1316px #ffffff,
      1442px 1692px #ffffff, 977px 652px #ffffff, 564px 1648px #ffffff,
      997px 1474px #ffffff, 67px 1366px #ffffff, 1860px 1451px #ffffff,
      1105px 772px #ffffff, 1886px 1396px #ffffff, 1510px 658px #ffffff,
      976px 1544px #ffffff, 894px 543px #ffffff, 1098px 1189px #ffffff,
      690px 77px #ffffff, 770px 733px #ffffff, 557px 1403px #ffffff,
      1758px 1623px #ffffff, 1341px 812px #ffffff, 699px 967px #ffffff,
      277px 866px #ffffff, 1526px 1828px #ffffff, 8px 977px #ffffff,
      1707px 952px #ffffff, 12px 1900px #ffffff, 72px 921px #ffffff,
      496px 1067px #ffffff, 1288px 1749px #ffffff, 273px 984px #ffffff,
      1197px 1991px #ffffff, 242px 789px #ffffff, 903px 1035px #ffffff,
      480px 1492px #ffffff, 102px 1331px #ffffff, 738px 1343px #ffffff,
      560px 1475px #ffffff, 367px 846px #ffffff, 1420px 962px #ffffff,
      1976px 892px #ffffff, 1911px 1763px #ffffff, 1639px 1002px #ffffff,
      437px 1522px #ffffff, 1906px 1025px #ffffff, 730px 1364px #ffffff,
      1127px 521px #ffffff, 1401px 1792px #ffffff, 1954px 1066px #ffffff,
      232px 250px #ffffff, 1685px 660px #ffffff, 1011px 999px #ffffff,
      1970px 790px #ffffff, 750px 499px #ffffff, 1738px 660px #ffffff,
      1621px 1849px #ffffff, 446px 52px #ffffff, 1055px 1396px #ffffff,
      1165px 1497px #ffffff, 1740px 1425px #ffffff, 1012px 1920px #ffffff,
      1258px 1560px #ffffff, 1020px 1152px #ffffff, 362px 673px #ffffff,
      1065px 975px #ffffff, 582px 755px #ffffff, 1271px 1479px #ffffff,
      719px 548px #ffffff, 1602px 879px #ffffff, 590px 499px #ffffff,
      721px 1412px #ffffff, 1180px 113px #ffffff, 1801px 1961px #ffffff,
      589px 941px #ffffff, 883px 476px #ffffff, 214px 890px #ffffff,
      1028px 892px #ffffff, 1107px 1832px #ffffff, 944px 361px #ffffff,
      480px 1453px #ffffff, 1466px 683px #ffffff, 981px 745px #ffffff,
      1968px 828px #ffffff, 657px 1830px #ffffff, 11px 1338px #ffffff,
      179px 730px #ffffff, 1713px 197px #ffffff, 51px 955px #ffffff,
      1243px 319px #ffffff, 1175px 624px #ffffff, 446px 46px #ffffff,
      5px 1158px #ffffff, 82px 1352px #ffffff, 1877px 402px #ffffff,
      708px 1778px #ffffff, 903px 1625px #ffffff, 1824px 352px #ffffff,
      1229px 140px #ffffff, 1518px 24px #ffffff, 1017px 512px #ffffff,
      515px 699px #ffffff, 295px 265px #ffffff, 69px 1773px #ffffff,
      1640px 1163px #ffffff, 536px 342px #ffffff, 970px 1766px #ffffff,
      560px 1416px #ffffff, 577px 193px #ffffff, 469px 9px #ffffff,
      466px 276px #ffffff, 711px 853px #ffffff, 401px 685px #ffffff,
      85px 506px #ffffff, 865px 558px #ffffff, 631px 105px #ffffff,
      887px 866px #ffffff, 1704px 1001px #ffffff, 1051px 1199px #ffffff,
      275px 1909px #ffffff, 1462px 829px #ffffff, 375px 1057px #ffffff,
      1531px 1501px #ffffff, 205px 403px #ffffff, 33px 1869px #ffffff,
      967px 1176px #ffffff, 376px 863px #ffffff, 1769px 1545px #ffffff,
      535px 51px #ffffff, 1972px 1569px #ffffff, 1773px 960px #ffffff,
      487px 620px #ffffff, 1660px 687px #ffffff, 1632px 972px #ffffff,
      1362px 42px #ffffff, 479px 1655px #ffffff, 1531px 1808px #ffffff,
      1450px 1412px #ffffff, 1549px 170px #ffffff, 1904px 1305px #ffffff,
      1209px 48px #ffffff, 1933px 820px #ffffff, 1623px 595px #ffffff,
      48px 643px #ffffff, 179px 1754px #ffffff, 589px 1032px #ffffff,
      1199px 356px #ffffff, 1755px 1418px #ffffff, 780px 1174px #ffffff,
      1905px 758px #ffffff, 1567px 713px #ffffff, 1372px 705px #ffffff,
      456px 654px #ffffff, 759px 690px #ffffff, 452px 673px #ffffff,
      993px 1610px #ffffff, 1271px 188px #ffffff, 343px 1750px #ffffff,
      1943px 1735px #ffffff, 1717px 853px #ffffff, 1247px 303px #ffffff,
      1314px 1895px #ffffff, 1203px 489px #ffffff, 741px 469px #ffffff,
      4px 246px #ffffff, 1515px 115px #ffffff, 606px 218px #ffffff,
      1966px 1471px #ffffff, 177px 87px #ffffff, 1575px 588px #ffffff,
      1136px 1386px #ffffff, 70px 1868px #ffffff, 1053px 18px #ffffff,
      1124px 721px #ffffff, 1748px 1181px #ffffff, 191px 1387px #ffffff,
      1931px 840px #ffffff, 1088px 1603px #ffffff, 634px 1255px #ffffff,
      814px 1434px #ffffff, 585px 64px #ffffff, 1074px 1618px #ffffff,
      1692px 761px #ffffff, 651px 643px #ffffff, 193px 335px #ffffff,
      1103px 1447px #ffffff, 491px 1142px #ffffff, 521px 408px #ffffff,
      536px 340px #ffffff, 411px 1091px #ffffff, 1646px 193px #ffffff,
      1595px 1285px #ffffff, 870px 1349px #ffffff, 1085px 1013px #ffffff,
      204px 1864px #ffffff, 1359px 299px #ffffff, 807px 964px #ffffff,
      219px 509px #ffffff, 36px 1227px #ffffff, 702px 1873px #ffffff,
      1471px 934px #ffffff, 1763px 792px #ffffff, 973px 1957px #ffffff,
      987px 68px #ffffff, 593px 1282px #ffffff, 1900px 607px #ffffff,
      407px 1659px #ffffff, 587px 17px #ffffff, 632px 158px #ffffff;
    animation: animStar 600s linear infinite;

    &::after {
      content: " ";
      position: absolute;
      top: 0px;
      width: 1px;
      height: 1px;
      background: transparent;
      box-shadow: 233px 1976px #ffffff, 1196px 1119px #ffffff,
        646px 740px #ffffff, 335px 645px #ffffff, 1119px 1452px #ffffff,
        176px 1870px #ffffff, 639px 1711px #ffffff, 647px 1388px #ffffff,
        1516px 1108px #ffffff, 464px 66px #ffffff, 331px 344px #ffffff,
        772px 1189px #ffffff, 1516px 1850px #ffffff, 1500px 1463px #ffffff,
        1275px 876px #ffffff, 1107px 645px #ffffff, 977px 478px #ffffff,
        583px 1179px #ffffff, 284px 395px #ffffff, 1220px 461px #ffffff,
        1160px 249px #ffffff, 196px 865px #ffffff, 670px 1915px #ffffff,
        1449px 382px #ffffff, 1191px 546px #ffffff, 1329px 605px #ffffff,
        1945px 458px #ffffff, 995px 749px #ffffff, 1495px 861px #ffffff,
        708px 1731px #ffffff, 348px 653px #ffffff, 548px 1298px #ffffff,
        1606px 990px #ffffff, 1049px 1204px #ffffff, 253px 1501px #ffffff,
        1154px 166px #ffffff, 1087px 104px #ffffff, 1034px 1161px #ffffff,
        1681px 462px #ffffff, 577px 1897px #ffffff, 193px 1901px #ffffff,
        1701px 1755px #ffffff, 864px 1297px #ffffff, 800px 1289px #ffffff,
        676px 28px #ffffff, 185px 1341px #ffffff, 379px 1151px #ffffff,
        1224px 1725px #ffffff, 280px 541px #ffffff, 473px 1196px #ffffff,
        921px 1628px #ffffff, 969px 432px #ffffff, 1475px 758px #ffffff,
        1195px 993px #ffffff, 876px 1840px #ffffff, 1274px 1689px #ffffff,
        1977px 1101px #ffffff, 837px 527px #ffffff, 1785px 1610px #ffffff,
        1650px 1843px #ffffff, 1127px 1508px #ffffff, 401px 1050px #ffffff,
        51px 1105px #ffffff, 545px 880px #ffffff, 1786px 1672px #ffffff,
        318px 260px #ffffff, 568px 254px #ffffff, 1026px 1527px #ffffff,
        1242px 852px #ffffff, 1785px 982px #ffffff, 1318px 1071px #ffffff,
        398px 1061px #ffffff, 1509px 257px #ffffff, 599px 928px #ffffff,
        1195px 1800px #ffffff, 1254px 906px #ffffff, 141px 26px #ffffff,
        1384px 1502px #ffffff, 476px 767px #ffffff, 1973px 722px #ffffff,
        1339px 1031px #ffffff, 778px 818px #ffffff, 213px 1320px #ffffff,
        184px 221px #ffffff, 983px 1911px #ffffff, 923px 1439px #ffffff,
        1936px 581px #ffffff, 1105px 625px #ffffff, 325px 729px #ffffff,
        1475px 204px #ffffff, 1483px 1564px #ffffff, 1327px 1272px #ffffff,
        1187px 1944px #ffffff, 1945px 1471px #ffffff, 116px 960px #ffffff,
        1660px 1610px #ffffff, 412px 1022px #ffffff, 1552px 1516px #ffffff,
        1517px 1892px #ffffff, 306px 829px #ffffff, 1416px 462px #ffffff,
        1575px 1460px #ffffff, 424px 1500px #ffffff, 1530px 1169px #ffffff,
        1388px 1608px #ffffff, 185px 416px #ffffff, 634px 1446px #ffffff,
        767px 479px #ffffff, 71px 426px #ffffff, 1937px 145px #ffffff,
        1955px 1312px #ffffff, 1811px 611px #ffffff, 1145px 569px #ffffff,
        1460px 676px #ffffff, 131px 1858px #ffffff, 1557px 473px #ffffff,
        735px 130px #ffffff, 112px 1531px #ffffff, 1312px 305px #ffffff,
        409px 1032px #ffffff, 149px 1964px #ffffff, 535px 1215px #ffffff,
        1382px 630px #ffffff, 1437px 1368px #ffffff, 362px 1181px #ffffff,
        388px 181px #ffffff, 274px 1287px #ffffff, 1858px 1414px #ffffff,
        661px 1935px #ffffff, 675px 1205px #ffffff, 1829px 1725px #ffffff,
        1937px 1145px #ffffff, 237px 908px #ffffff, 1059px 1185px #ffffff,
        824px 1248px #ffffff, 1167px 1730px #ffffff, 180px 1961px #ffffff,
        1663px 203px #ffffff, 374px 221px #ffffff, 724px 1883px #ffffff,
        970px 1362px #ffffff, 832px 505px #ffffff, 313px 233px #ffffff,
        1909px 597px #ffffff, 434px 201px #ffffff, 587px 995px #ffffff,
        1833px 623px #ffffff, 1464px 561px #ffffff, 231px 593px #ffffff,
        1558px 1433px #ffffff, 1986px 1767px #ffffff, 1753px 1728px #ffffff,
        1153px 1623px #ffffff, 249px 229px #ffffff, 1503px 1186px #ffffff,
        1784px 137px #ffffff, 841px 403px #ffffff, 1400px 354px #ffffff,
        197px 499px #ffffff, 1188px 681px #ffffff, 158px 391px #ffffff,
        443px 1099px #ffffff, 723px 1445px #ffffff, 1408px 1235px #ffffff,
        1908px 195px #ffffff, 271px 891px #ffffff, 469px 1693px #ffffff,
        580px 11px #ffffff, 1533px 70px #ffffff, 859px 761px #ffffff,
        1510px 1844px #ffffff, 421px 558px #ffffff, 1132px 1453px #ffffff,
        757px 1987px #ffffff, 212px 293px #ffffff, 569px 323px #ffffff,
        1404px 1394px #ffffff, 252px 1386px #ffffff, 1668px 1857px #ffffff,
        123px 1684px #ffffff, 105px 490px #ffffff, 1083px 1769px #ffffff,
        1071px 1953px #ffffff, 1271px 1159px #ffffff, 699px 1491px #ffffff,
        1744px 1997px #ffffff, 1868px 1973px #ffffff, 1438px 1449px #ffffff,
        1222px 1921px #ffffff, 1328px 1210px #ffffff, 438px 873px #ffffff,
        809px 780px #ffffff, 491px 1524px #ffffff, 447px 1830px #ffffff,
        927px 1936px #ffffff, 564px 691px #ffffff, 1784px 1747px #ffffff,
        1978px 1722px #ffffff, 1599px 1480px #ffffff, 1276px 729px #ffffff,
        731px 1174px #ffffff, 1586px 1711px #ffffff, 451px 1340px #ffffff,
        1075px 1899px #ffffff, 13px 575px #ffffff, 309px 1340px #ffffff,
        981px 183px #ffffff, 248px 1315px #ffffff, 849px 80px #ffffff,
        1754px 1540px #ffffff, 73px 1432px #ffffff, 1208px 1828px #ffffff,
        65px 575px #ffffff, 1098px 730px #ffffff, 127px 1358px #ffffff,
        185px 19px #ffffff, 1222px 1679px #ffffff, 1122px 315px #ffffff,
        1906px 452px #ffffff, 761px 284px #ffffff, 813px 492px #ffffff,
        1344px 843px #ffffff, 118px 1834px #ffffff, 1620px 359px #ffffff,
        1755px 1246px #ffffff, 299px 1076px #ffffff, 1746px 158px #ffffff,
        6px 1635px #ffffff, 143px 190px #ffffff, 101px 468px #ffffff,
        137px 971px #ffffff, 1221px 1929px #ffffff, 1752px 650px #ffffff,
        1635px 1761px #ffffff, 1522px 833px #ffffff, 908px 153px #ffffff,
        1044px 350px #ffffff, 1151px 1940px #ffffff, 822px 210px #ffffff,
        1774px 310px #ffffff, 796px 1447px #ffffff, 1069px 1903px #ffffff,
        217px 565px #ffffff, 662px 1370px #ffffff, 1876px 1570px #ffffff,
        847px 46px #ffffff, 1042px 1689px #ffffff, 1584px 1434px #ffffff,
        1791px 908px #ffffff, 973px 908px #ffffff, 793px 747px #ffffff,
        122px 483px #ffffff, 1137px 1374px #ffffff, 1757px 1791px #ffffff,
        513px 225px #ffffff, 63px 731px #ffffff, 1179px 1926px #ffffff,
        346px 18px #ffffff, 589px 175px #ffffff, 87px 302px #ffffff,
        380px 1295px #ffffff, 450px 921px #ffffff, 1667px 1973px #ffffff,
        1495px 1373px #ffffff, 1462px 1850px #ffffff, 540px 288px #ffffff,
        1208px 1051px #ffffff, 1554px 1095px #ffffff, 1009px 1516px #ffffff,
        181px 572px #ffffff, 165px 387px #ffffff, 549px 1835px #ffffff,
        960px 16px #ffffff, 1360px 403px #ffffff, 1251px 43px #ffffff,
        1905px 1813px #ffffff, 1106px 866px #ffffff, 1809px 277px #ffffff,
        1828px 1720px #ffffff, 295px 1610px #ffffff, 523px 166px #ffffff,
        1069px 692px #ffffff, 1292px 217px #ffffff, 11px 1721px #ffffff,
        99px 1045px #ffffff, 51px 1584px #ffffff, 1053px 266px #ffffff,
        1287px 1235px #ffffff, 747px 1722px #ffffff, 1542px 736px #ffffff,
        1256px 18px #ffffff, 102px 609px #ffffff, 586px 1339px #ffffff,
        1843px 1697px #ffffff, 824px 1687px #ffffff, 1124px 882px #ffffff,
        395px 501px #ffffff, 1456px 672px #ffffff, 1472px 1648px #ffffff,
        1326px 1164px #ffffff, 777px 1672px #ffffff, 81px 345px #ffffff,
        91px 386px #ffffff, 243px 411px #ffffff, 1560px 90px #ffffff,
        6px 1771px #ffffff, 1601px 616px #ffffff, 1220px 1808px #ffffff,
        1160px 836px #ffffff, 246px 1777px #ffffff, 456px 863px #ffffff,
        97px 1138px #ffffff, 1811px 942px #ffffff, 213px 414px #ffffff,
        891px 392px #ffffff, 1044px 927px #ffffff, 1856px 216px #ffffff,
        957px 347px #ffffff, 1486px 406px #ffffff, 838px 912px #ffffff,
        803px 361px #ffffff, 564px 826px #ffffff, 1597px 949px #ffffff,
        1206px 289px #ffffff, 33px 1035px #ffffff, 1762px 1377px #ffffff,
        789px 1815px #ffffff, 1594px 1342px #ffffff, 1668px 880px #ffffff,
        1539px 1581px #ffffff, 1547px 53px #ffffff, 861px 1433px #ffffff,
        693px 1618px #ffffff, 1762px 782px #ffffff, 1568px 682px #ffffff,
        1126px 1762px #ffffff, 1242px 134px #ffffff, 495px 959px #ffffff,
        1606px 219px #ffffff, 1878px 1415px #ffffff, 1652px 797px #ffffff,
        782px 1903px #ffffff, 1774px 1133px #ffffff, 1430px 408px #ffffff,
        265px 394px #ffffff, 890px 336px #ffffff, 1051px 311px #ffffff,
        461px 1559px #ffffff, 1931px 91px #ffffff, 1160px 380px #ffffff,
        1442px 1058px #ffffff, 1157px 364px #ffffff, 586px 227px #ffffff,
        1365px 715px #ffffff, 1658px 1655px #ffffff, 1923px 1664px #ffffff,
        1023px 1844px #ffffff, 1939px 1367px #ffffff, 1203px 1305px #ffffff,
        359px 642px #ffffff, 1056px 425px #ffffff, 787px 202px #ffffff,
        1609px 1850px #ffffff, 1964px 200px #ffffff, 1537px 586px #ffffff,
        1589px 903px #ffffff, 1063px 1694px #ffffff, 760px 1185px #ffffff,
        597px 1396px #ffffff, 294px 452px #ffffff, 433px 818px #ffffff,
        199px 840px #ffffff, 1332px 1937px #ffffff, 169px 1907px #ffffff,
        591px 834px #ffffff, 1716px 1032px #ffffff, 45px 1879px #ffffff,
        686px 1469px #ffffff, 1520px 475px #ffffff, 1122px 859px #ffffff,
        973px 1541px #ffffff, 269px 477px #ffffff, 1390px 716px #ffffff,
        1791px 783px #ffffff, 824px 2000px #ffffff, 1211px 1717px #ffffff,
        1008px 1587px #ffffff, 1422px 204px #ffffff, 234px 556px #ffffff,
        506px 550px #ffffff, 942px 1670px #ffffff, 397px 853px #ffffff,
        599px 795px #ffffff, 762px 1926px #ffffff, 1202px 1424px #ffffff,
        135px 1316px #ffffff, 1442px 1692px #ffffff, 977px 652px #ffffff,
        564px 1648px #ffffff, 997px 1474px #ffffff, 67px 1366px #ffffff,
        1860px 1451px #ffffff, 1105px 772px #ffffff, 1886px 1396px #ffffff,
        1510px 658px #ffffff, 976px 1544px #ffffff, 894px 543px #ffffff,
        1098px 1189px #ffffff, 690px 77px #ffffff, 770px 733px #ffffff,
        557px 1403px #ffffff, 1758px 1623px #ffffff, 1341px 812px #ffffff,
        699px 967px #ffffff, 277px 866px #ffffff, 1526px 1828px #ffffff,
        8px 977px #ffffff, 1707px 952px #ffffff, 12px 1900px #ffffff,
        72px 921px #ffffff, 496px 1067px #ffffff, 1288px 1749px #ffffff,
        273px 984px #ffffff, 1197px 1991px #ffffff, 242px 789px #ffffff,
        903px 1035px #ffffff, 480px 1492px #ffffff, 102px 1331px #ffffff,
        738px 1343px #ffffff, 560px 1475px #ffffff, 367px 846px #ffffff,
        1420px 962px #ffffff, 1976px 892px #ffffff, 1911px 1763px #ffffff,
        1639px 1002px #ffffff, 437px 1522px #ffffff, 1906px 1025px #ffffff,
        730px 1364px #ffffff, 1127px 521px #ffffff, 1401px 1792px #ffffff,
        1954px 1066px #ffffff, 232px 250px #ffffff, 1685px 660px #ffffff,
        1011px 999px #ffffff, 1970px 790px #ffffff, 750px 499px #ffffff,
        1738px 660px #ffffff, 1621px 1849px #ffffff, 446px 52px #ffffff,
        1055px 1396px #ffffff, 1165px 1497px #ffffff, 1740px 1425px #ffffff,
        1012px 1920px #ffffff, 1258px 1560px #ffffff, 1020px 1152px #ffffff,
        362px 673px #ffffff, 1065px 975px #ffffff, 582px 755px #ffffff,
        1271px 1479px #ffffff, 719px 548px #ffffff, 1602px 879px #ffffff,
        590px 499px #ffffff, 721px 1412px #ffffff, 1180px 113px #ffffff,
        1801px 1961px #ffffff, 589px 941px #ffffff, 883px 476px #ffffff,
        214px 890px #ffffff, 1028px 892px #ffffff, 1107px 1832px #ffffff,
        944px 361px #ffffff, 480px 1453px #ffffff, 1466px 683px #ffffff,
        981px 745px #ffffff, 1968px 828px #ffffff, 657px 1830px #ffffff,
        11px 1338px #ffffff, 179px 730px #ffffff, 1713px 197px #ffffff,
        51px 955px #ffffff, 1243px 319px #ffffff, 1175px 624px #ffffff,
        446px 46px #ffffff, 5px 1158px #ffffff, 82px 1352px #ffffff,
        1877px 402px #ffffff, 708px 1778px #ffffff, 903px 1625px #ffffff,
        1824px 352px #ffffff, 1229px 140px #ffffff, 1518px 24px #ffffff,
        1017px 512px #ffffff, 515px 699px #ffffff, 295px 265px #ffffff,
        69px 1773px #ffffff, 1640px 1163px #ffffff, 536px 342px #ffffff,
        970px 1766px #ffffff, 560px 1416px #ffffff, 577px 193px #ffffff,
        469px 9px #ffffff, 466px 276px #ffffff, 711px 853px #ffffff,
        401px 685px #ffffff, 85px 506px #ffffff, 865px 558px #ffffff,
        631px 105px #ffffff, 887px 866px #ffffff, 1704px 1001px #ffffff,
        1051px 1199px #ffffff, 275px 1909px #ffffff, 1462px 829px #ffffff,
        375px 1057px #ffffff, 1531px 1501px #ffffff, 205px 403px #ffffff,
        33px 1869px #ffffff, 967px 1176px #ffffff, 376px 863px #ffffff,
        1769px 1545px #ffffff, 535px 51px #ffffff, 1972px 1569px #ffffff,
        1773px 960px #ffffff, 487px 620px #ffffff, 1660px 687px #ffffff,
        1632px 972px #ffffff, 1362px 42px #ffffff, 479px 1655px #ffffff,
        1531px 1808px #ffffff, 1450px 1412px #ffffff, 1549px 170px #ffffff,
        1904px 1305px #ffffff, 1209px 48px #ffffff, 1933px 820px #ffffff,
        1623px 595px #ffffff, 48px 643px #ffffff, 179px 1754px #ffffff,
        589px 1032px #ffffff, 1199px 356px #ffffff, 1755px 1418px #ffffff,
        780px 1174px #ffffff, 1905px 758px #ffffff, 1567px 713px #ffffff,
        1372px 705px #ffffff, 456px 654px #ffffff, 759px 690px #ffffff,
        452px 673px #ffffff, 993px 1610px #ffffff, 1271px 188px #ffffff,
        343px 1750px #ffffff, 1943px 1735px #ffffff, 1717px 853px #ffffff,
        1247px 303px #ffffff, 1314px 1895px #ffffff, 1203px 489px #ffffff,
        741px 469px #ffffff, 4px 246px #ffffff, 1515px 115px #ffffff,
        606px 218px #ffffff, 1966px 1471px #ffffff, 177px 87px #ffffff,
        1575px 588px #ffffff, 1136px 1386px #ffffff, 70px 1868px #ffffff,
        1053px 18px #ffffff, 1124px 721px #ffffff, 1748px 1181px #ffffff,
        191px 1387px #ffffff, 1931px 840px #ffffff, 1088px 1603px #ffffff,
        634px 1255px #ffffff, 814px 1434px #ffffff, 585px 64px #ffffff,
        1074px 1618px #ffffff, 1692px 761px #ffffff, 651px 643px #ffffff,
        193px 335px #ffffff, 1103px 1447px #ffffff, 491px 1142px #ffffff,
        521px 408px #ffffff, 536px 340px #ffffff, 411px 1091px #ffffff,
        1646px 193px #ffffff, 1595px 1285px #ffffff, 870px 1349px #ffffff,
        1085px 1013px #ffffff, 204px 1864px #ffffff, 1359px 299px #ffffff,
        807px 964px #ffffff, 219px 509px #ffffff, 36px 1227px #ffffff,
        702px 1873px #ffffff, 1471px 934px #ffffff, 1763px 792px #ffffff,
        973px 1957px #ffffff, 987px 68px #ffffff, 593px 1282px #ffffff,
        1900px 607px #ffffff, 407px 1659px #ffffff, 587px 17px #ffffff,
        632px 158px #ffffff;
    }
  }

  @keyframes animStar {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-800px);
    }
  }
`
